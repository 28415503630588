import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { Button, IconButton, Popover } from '@mui/material';

import { paths } from 'src/app/routes';
import Page from 'src/layouts/BaseLayout/components/Page/Page';

import { ErrorResponse } from 'src/shared/models';
import Icon from 'src/shared/components/Icon/Icon';
import Card from 'src/shared/components/Card/Card';
import Input from 'src/shared/components/Input/Input';
import Spinner from 'src/shared/components/Spinner/Spinner';
import TextArea from 'src/shared/components/TextArea/TextArea';
import Dropdown from 'src/shared/components/Dropdown/Dropdown';
import { IconType } from 'src/shared/components/Icon/IconType';
import Select, { OTHER } from 'src/shared/components/Select/Select';
import DatePicker from 'src/shared/components/DatePicker/DatePicker';
import {
  REQUEST_DATE_FORMAT,
  downloadDocument,
  makeSelectorOptions,
  openDocumentWithAuthorization,
} from 'src/shared/utils';

import { MODALS } from 'src/features/modal/models';
import { UserRoles } from 'src/features/auth/models';
import { State, store } from 'src/features/store/store';
import { AUTH } from 'src/features/auth/slices/authSlice';
import { useCheckAuthorization } from 'src/features/hooks';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import {
  AREAS_OPTIONS,
  DeckCapacity,
  DualCapacity,
  EngineeringCapacity,
  InteriorCapacity,
  Testimonial,
  TestimonialRequestStatus,
  TestimonialType,
  VesselType,
} from 'src/features/testimonials/models';
import {
  useGetTestimonialByIdQuery,
  useUpdateTestimonialMutation,
} from 'src/features/testimonials/api/testimonialsApi';
import { useGetDocumentQuery } from 'src/features/documents/api/documentsApi';
import { setRequestId } from 'src/features/testimonials/slices/testimonialSlice';
import { ResponsiblePersonCapacity } from 'src/features/responsiblePersons/models';
import { setResponsiblePerson } from 'src/features/responsiblePersons/slices/responsiblePersonsSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';

import LeaveDatesInput from './components/LeaveDatesInput/LeaveDatesInput';
import ResponsiblePersonPositionInput from './components/ResponsiblePersonPositionInput/ResponsiblePersonPositionInput';

import './SSTRequestDetailed.scss';

const CAPACITY_OPTIONS = {
  [TestimonialType.DECK]: makeSelectorOptions(DeckCapacity),
  [TestimonialType.ENGINEERING]: makeSelectorOptions(EngineeringCapacity),
  [TestimonialType.DUAL]: makeSelectorOptions(DualCapacity),
  [TestimonialType.INTERIOR]: makeSelectorOptions(InteriorCapacity),
};

const RP_CAPACITY_OPTIONS = makeSelectorOptions(ResponsiblePersonCapacity);

const VESSEL_TYPE_OPTIONS = makeSelectorOptions(VesselType);

const HeaderTypes = {
  [TestimonialType.DECK]: 'Deck Testimonial for Masters or Deck Officers',
  [TestimonialType.ENGINEERING]:
    'Engineering Testimonial for Small Vessel Route Engineers',
  [TestimonialType.DUAL]:
    'Dual role Testimonial for crew signed on as Deck/Engineer role',
  [TestimonialType.INTERIOR]:
    'Chef/Cook, Interior crew, and Dual Role Testimonial',
};

const validationSchema = Yup.object().shape(
  {
    actualDays: Yup.number().when(
      ['dateTo', 'dateFrom', 'daysLeave', 'daysStandBy', 'daysShipyard'],
      ([dateTo, dateFrom, daysLeave, daysStandBy, daysShipyard], schema) => {
        return schema.max(
          dayjs(dateTo).diff(dayjs(dateFrom), 'day') +
            1 -
            daysLeave -
            daysStandBy -
            daysShipyard,
          'Total onboarding service does not match values provided'
        );
      }
    ),
    daysStandBy: Yup.number()
      .max(
        Yup.ref('actualDays'),
        'Total standby days higher than actual sea days'
      )
      .when(
        ['dateTo', 'dateFrom', 'daysLeave', 'actualDays', 'daysShipyard'],
        ([dateTo, dateFrom, daysLeave, actualDays, daysShipyard], schema) => {
          return schema.max(
            dayjs(dateTo).diff(dayjs(dateFrom), 'day') +
              1 -
              daysLeave -
              actualDays -
              daysShipyard,
            'Total onboarding service does not match values provided'
          );
        }
      ),
    daysShipyard: Yup.number().when(
      ['dateTo', 'dateFrom', 'daysLeave', 'actualDays', 'daysStandBy'],
      ([dateTo, dateFrom, daysLeave, actualDays, daysStandBy], schema) => {
        return schema.max(
          dayjs(dateTo).diff(dayjs(dateFrom), 'day') +
            1 -
            daysLeave -
            actualDays -
            daysStandBy,
          'Total onboarding service does not match values provided'
        );
      }
    ),
    daysWatchKeeping: Yup.number().max(
      Yup.ref('actualDays'),
      'Watchkeeping days cannot be higher than actual days at sea'
    ),
    daysSeagoingWatchKeeping: Yup.number().max(
      Yup.ref('actualDays'),
      'Seagoing Watchkeeping days cannot be higher than actual sea service'
    ),
    dateFrom: Yup.date()
      .nullable()
      .default(new Date())
      .required('This field is required'),
    dateTo: Yup.date()
      .nullable()
      .default(new Date())
      .required('This field is required'),
    areasCruised: Yup.array().min(1, 'This field is required'),
  },
  [
    ['actualDays', 'daysStandBy'],
    ['actualDays', 'daysShipyard'],
    ['daysShipyard', 'daysStandBy'],
  ]
);

const vesselSchema = {
  vesselType: Yup.string().required('This field is required'),
  vesselTypeOther: Yup.string()
    .ensure()
    .when('vesselType', {
      is: 'Other',
      then: (schema) => schema.required('This field is required'),
    }),
  name: Yup.string().required('This field is required'),
  flag: Yup.string().required('This field is required'),
  officialNumber: Yup.string().required('This field is required'),
  grossTonnage: Yup.string().required('This field is required'),
};

const seafarerSchema = {
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  dateOfBirth: Yup.string().required('This field is required'),
};

const downloadPaymentReceipt = (id: string) =>
  openDocumentWithAuthorization(
    `${process.env.REACT_APP_DOCUMENT_URL}/document/download/${id}`
  );

const SSTRequestDetailed = () => {
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDocumentAttached, setIsDocumentAttached] = useState<boolean>(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(false);
  const [disabledForm, setDisabledForm] = useState<boolean>(true);
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const userRoles = useSelector((s: State) => s[AUTH].roles);

  const { data: testimonial } = useGetTestimonialByIdQuery(id as string, {
    skip: !id,
  });
  const { isSuccess } = useGetDocumentQuery(id as string, {
    skip: !id,
  });

  const testimonialType = useMemo(
    () => testimonial?.data.testimonialType,
    [testimonial?.data.testimonialType]
  );
  const [updateRequest, updateResult] = useUpdateTestimonialMutation();

  const openDocument = useCallback(() => {
    setIsDocumentLoading(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/testimonial/${id}/document?preview=true`,
      {
        headers: new Headers({
          authorization: `Bearer ${localStorage.getItem('ncap-token')}`,
        }),
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const _url = window.URL.createObjectURL(blob);
        window.open(_url, '_blank');
        setIsDocumentLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Preview document error', {
          containerId: CONTAINER_ID_ACTION,
        });
        setIsDocumentLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (updateResult.isError) {
      toast.error(
        `Updating error: ${(updateResult.error as ErrorResponse).data.message}`,
        {
          containerId: CONTAINER_ID_ACTION,
        }
      );
      setDisabledForm(false);
    } else if (updateResult.isSuccess) {
      toast.success('Successfully updated', {
        containerId: CONTAINER_ID_ACTION,
      });
      setDisabledForm(true);
    }
  }, [updateResult]);

  useCheckAuthorization();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/testimonial/${id}/document`, {
      headers: new Headers({
        authorization: `Bearer ${localStorage.getItem('ncap-token')}`,
      }),
    })
      .then((res) => setIsDocumentAttached(res.status === 200))
      .catch((error) => console.log(error));
  }, [id]);

  const isVisible = useMemo(
    () =>
      (testimonial?.data.status === TestimonialRequestStatus.VALIDATED_PYA &&
        userRoles?.includes(UserRoles.PYA_ADMIN)) ||
      testimonial?.data.status === TestimonialRequestStatus.SENT_PYA,
    [testimonial?.data.status, userRoles]
  );

  const isApproved = useMemo(
    () => testimonial?.data.status === TestimonialRequestStatus.APPROVED,
    [testimonial?.data.status]
  );

  const dropdownItems = useMemo(() => {
    const res = [
      {
        text: 'Download Certificate',
        icon: <Icon icon={IconType.Download} />,
        onClick: (testimonial: Testimonial) => {
          downloadDocument(testimonial.certificateId);
        },
        disabled: (request: Testimonial) => !request.certificateId,
      },
      {
        text: 'Download payment receipt',
        icon: <Icon icon={IconType.Download} />,
        onClick: (testimonial: Testimonial) => {
          downloadPaymentReceipt(testimonial.uid);
        },
        disabled: () => !isSuccess,
      },
      {
        text: 'Verify RP',
        icon: <Icon icon={IconType.Verify} />,
        onClick: () => {
          store.dispatch(changeModal(MODALS.VERIFY_RP_FORM));
          store.dispatch(
            setResponsiblePerson(testimonial?.data.responsiblePerson ?? null)
          );
        },
      },
    ];
    if (isVisible) {
      res.splice(0, 0, {
        text: 'Edit',
        icon: <Icon icon={IconType.Pencil} />,
        onClick: () => setDisabledForm(false),
      });
      res.splice(3, 0, {
        text: 'Reject',
        icon: <Icon icon={IconType.ArrowBack} />,
        onClick: (testimonial: Testimonial) => {
          store.dispatch(changeModal(MODALS.RETURN_TO_SEAFARER));
          store.dispatch(setRequestId(testimonial.uid));
        },
        disabled: () => false,
      });
    }
    return res;
  }, [isSuccess, isVisible, testimonial?.data.responsiblePerson]);

  const dropdownApprovedItems = useMemo(
    () => [
      {
        text: 'Download Certificate',
        icon: <Icon icon={IconType.Download} />,
        onClick: (testimonial: Testimonial) => {
          downloadDocument(testimonial.certificateId);
        },
        disabled: (request: Testimonial) => !request.certificateId,
      },
      {
        text: 'Download payment receipt',
        icon: <Icon icon={IconType.Download} />,
        onClick: (testimonial: Testimonial) => {
          downloadPaymentReceipt(testimonial.uid);
        },
        disabled: () => !isSuccess,
      },
      {
        text: 'Verify RP',
        icon: <Icon icon={IconType.Verify} />,
        onClick: () => {
          store.dispatch(changeModal(MODALS.VERIFY_RP_FORM));
          store.dispatch(
            setResponsiblePerson(testimonial?.data.responsiblePerson ?? null)
          );
        },
      },
    ],
    [isSuccess, testimonial?.data.responsiblePerson]
  );

  const initialFormValues = useMemo<Testimonial | undefined>(
    () => testimonial?.data,
    [testimonial]
  );

  const requestValidationSchema = useMemo(() => {
    const deckSchema = validationSchema.concat(
      Yup.object({
        vessel: Yup.object({
          ...vesselSchema,
          length: Yup.string().required('This field is required'),
        }),
        seafarer: Yup.object({
          ...seafarerSchema,
          capacity: Yup.string().required('This field is required'),
        }),
      })
    );
    const daysLeave = Yup.string().required('This field is required');
    switch (testimonialType) {
      case TestimonialType.DECK:
        return deckSchema;
      case TestimonialType.ENGINEERING:
        return validationSchema.concat(
          Yup.object({
            daysLeave,
            vessel: Yup.object({
              ...vesselSchema,
              mainEnginePower: Yup.number().required('This field is required'),
            }),
            seafarer: Yup.object({
              ...seafarerSchema,
              capacity: Yup.string().required('This field is required'),
            }),
          })
        );
      case TestimonialType.DUAL:
        return validationSchema.concat(
          Yup.object({
            daysLeave,
            vessel: Yup.object({
              ...vesselSchema,
              length: Yup.string().required('This field is required'),
              mainEnginePower: Yup.number().required('This field is required'),
            }),
            seafarer: Yup.object({
              ...seafarerSchema,
              capacity: Yup.string().required('This field is required'),
            }),
          })
        );
      case TestimonialType.INTERIOR:
        return validationSchema.concat(
          Yup.object({
            daysLeave,
            vessel: Yup.object({
              ...vesselSchema,
              length: Yup.string().required('This field is required'),
            }),
            seafarer: Yup.object({
              ...seafarerSchema,
              capacity: Yup.string().required('This field is required'),
            }),
          })
        );
      default:
        return deckSchema;
    }
  }, [testimonialType]);

  const dropdownIsOpen = Boolean(anchorEl);
  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  const onApproveClick = useCallback(() => {
    store.dispatch(changeModal(MODALS.APPROVE_SST));
    id && store.dispatch(setRequestId(id));
  }, [id]);

  const onValidateClick = useCallback(() => {
    store.dispatch(changeModal(MODALS.VALIDATE_SST));
    id && store.dispatch(setRequestId(id));
  }, [id]);

  const onReturnToPRClick = useCallback(() => {
    store.dispatch(changeModal(MODALS.RETURN_TO_RP));
    id && store.dispatch(setRequestId(id));
  }, [id]);

  const onDeclineClick = useCallback(() => {
    store.dispatch(changeModal(MODALS.RETURN_TO_SEAFARER));
    id && store.dispatch(setRequestId(id));
  }, [id]);

  const onSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const onDiscard = () => {
    if (formRef.current) {
      formRef.current.handleReset();
      setDisabledForm(true);
    }
  };

  const filterLeaveDates = (dates: { from: string; to: string }[]) => {
    return dates.filter((elem) => elem.from !== '' || elem.to !== '');
  };

  const onUpdateRequest = (data: Testimonial | unknown) => {
    const values = data as Testimonial;
    const dates = filterLeaveDates(values.leaveDates);

    if (values && id) {
      updateRequest({
        ...values,
        uid: id,
        seafarer: {
          ...values.seafarer,
          capacity:
            values.seafarer.capacity !== OTHER
              ? values.seafarer.capacity
              : values.seafarer.capacityOther,
        },
        vessel: {
          ...values.vessel,
          vesselType:
            values.vessel.vesselType !== OTHER
              ? values.vessel.vesselType
              : values.vessel.vesselTypeOther,
        },
        responsiblePerson: {
          ...values.responsiblePerson,
          capacity:
            values.responsiblePerson.capacity !== OTHER
              ? values.responsiblePerson.capacity
              : values.responsiblePerson.capacityOther,
        },
        leaveDates: dates,
      });
    }
  };

  const approveButton = useMemo(() => {
    switch (testimonial?.data.status) {
      case TestimonialRequestStatus.VALIDATED_PYA:
        return userRoles?.includes(UserRoles.PYA_ADMIN) ? (
          <Button
            variant="contained"
            startIcon={
              <Icon
                icon={IconType.CheckCircle}
                className="sst-request-detailed__icon_approve"
              />
            }
            onClick={onApproveClick}
          >
            Approve
          </Button>
        ) : null;
      case TestimonialRequestStatus.SENT_PYA:
        return userRoles?.includes(UserRoles.PYA) ? (
          <Button
            variant="contained"
            startIcon={
              <Icon
                icon={IconType.CheckCircle}
                className="sst-request-detailed__icon_approve"
              />
            }
            onClick={onValidateClick}
          >
            Validate
          </Button>
        ) : null;
      default:
        return null;
    }
  }, [onApproveClick, onValidateClick, testimonial?.data.status, userRoles]);

  const returnToRpButton = useMemo(() => {
    if (isVisible) {
      return (
        <Button
          variant="outlined"
          color="secondary"
          startIcon={
            <Icon
              icon={IconType.ArrowBack}
              className="sst-request-detailed__icon_return"
            />
          }
          onClick={onReturnToPRClick}
        >
          Return to RP
        </Button>
      );
    }
  }, [isVisible, onReturnToPRClick]);

  const declineButton = useMemo(() => {
    if (isVisible) {
      return (
        <Button
          variant="outlined"
          color="error"
          startIcon={
            <Icon
              icon={IconType.Cross}
              className="sst-request-detailed__icon_error"
            />
          }
          onClick={onDeclineClick}
        >
          Decline SST
        </Button>
      );
    }
    return null;
  }, [isVisible, onDeclineClick]);

  const buttonsBlock = useMemo(() => {
    if (isApproved) {
      return null;
    }
    return (
      <>
        {approveButton}
        {returnToRpButton}
        {declineButton}
      </>
    );
  }, [approveButton, declineButton, isApproved, returnToRpButton]);

  const disabledButtonsBlock = useMemo(() => {
    return (
      <>
        <Button
          variant="contained"
          startIcon={
            <Icon
              icon={IconType.CheckCircle}
              className="sst-request-detailed__icon_approve"
            />
          }
          onClick={onSave}
        >
          Save changes
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={
            <Icon
              icon={IconType.ArrowBack}
              className="sst-request-detailed__icon_return"
            />
          }
          onClick={onDiscard}
        >
          Discard changes
        </Button>
      </>
    );
  }, []);

  const attachmentLabel = useMemo(() => {
    return (
      <div className="sst-request-detailed__attachment">
        <div className="sst-request-detailed__attachment-title">
          <Icon
            icon={IconType.WarningFilled}
            className="sst-request-detailed__attachment-icon"
          />
          <div>
            <h4 className="sst-request-detailed__attachment-header">
              This is a Historic SST
            </h4>
            <p className="sst-request-detailed__attachment-text">
              This SST was previously approved by PYA in a paper format.
            </p>
          </div>
        </div>
        {isDocumentLoading ? (
          <div className="sst-request-detailed__attachment-spinner-container">
            <Spinner />
          </div>
        ) : (
          <Button
            variant="outlined"
            className="sst-request-detailed__attachment-button"
            onClick={openDocument}
          >
            Preview Attachment
          </Button>
        )}
      </div>
    );
  }, [isDocumentLoading, openDocument]);

  return (
    <Page>
      <div className="sst-request-detailed">
        <div className="sst-request-detailed__header">
          <div className="sst-request-detailed__header_left">
            <Link to={isApproved ? paths.requestsApproved : paths.requests}>
              <Icon icon={IconType.Chevron} />
            </Link>
            <h1 className="request-header__title">
              {
                HeaderTypes[
                  (testimonialType as TestimonialType) ?? TestimonialType.DECK
                ]
              }
            </h1>
          </div>
          <div className="sst-request-detailed__header_right">
            {disabledForm ? buttonsBlock : disabledButtonsBlock}
            <IconButton
              className="sst-request__button_actions"
              onClick={handleActionsClick}
            >
              <Icon
                icon={IconType.Dots}
                className="sst-request-detailed__icon_actions"
              />
            </IconButton>
            <Popover
              open={dropdownIsOpen}
              anchorEl={anchorEl}
              onClose={handleActionsClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Dropdown
                elements={isApproved ? dropdownApprovedItems : dropdownItems}
                callbackOnClick={handleActionsClose}
                element={testimonial?.data}
              />
            </Popover>
          </div>
        </div>
        <Formik
          initialValues={initialFormValues ?? {}}
          enableReinitialize
          onSubmit={(values) => onUpdateRequest(values)}
          innerRef={formRef}
          validationSchema={requestValidationSchema}
        >
          <Form className="sst-request-form">
            {testimonial?.data.status === TestimonialRequestStatus.SENT_PYA &&
            testimonial?.data.rejectionReason ? (
              <Card className="sst-request-detailed__card">
                <h2 className="card__header card__header_warning">
                  This SST request has gone under revision by the responsible
                  person or the SRB Officer
                </h2>
                <TextArea name="rejectionReason" label="Comment" disabled />
              </Card>
            ) : null}
            <Card className="sst-request-detailed__card sst-request-detailed__card_first">
              {isDocumentAttached ? attachmentLabel : null}
              <h2 className="card__header">Personal information</h2>
              <div>
                <Input
                  name="seafarer.firstName"
                  label="First name"
                  disabled={disabledForm}
                  required
                />
                <Input
                  name="seafarer.middleName"
                  label="Middle name"
                  disabled={disabledForm}
                />
                <Input
                  name="seafarer.lastName"
                  label="Last name"
                  disabled={disabledForm}
                  required
                />
                <Input
                  name="seafarer.dateOfBirth"
                  label="Date of birth"
                  disabled={disabledForm}
                  required
                />
                <Input
                  name="seafarer.pyaMembershipNo"
                  label="PYA membership number"
                  type="email"
                  disabled={disabledForm}
                />
                <Select
                  name="seafarer.capacity"
                  label="Capacity"
                  options={
                    CAPACITY_OPTIONS[
                      (testimonialType as TestimonialType) ??
                        TestimonialType.DECK
                    ]
                  }
                  otherOption
                  disabled={disabledForm}
                  required
                />
              </div>

              <div>
                <h2 className="group__header">On board</h2>
                <Select
                  name="vessel.vesselType"
                  label="Vessel type"
                  placeholder="Choose vessel type"
                  options={VESSEL_TYPE_OPTIONS}
                  otherOption
                  disabled={disabledForm}
                  required
                />
                <Input
                  name="vessel.name"
                  label="Vessel Name"
                  disabled={disabledForm}
                  required
                />
                <Input
                  name="vessel.flag"
                  label="Flag"
                  disabled={disabledForm}
                  required
                />
                <Input
                  name="vessel.imoNumber"
                  label="IMO number"
                  disabled={disabledForm}
                />
                <Input
                  name="vessel.officialNumber"
                  label="Official number"
                  disabled={disabledForm}
                  required
                />
                <Input
                  name="vessel.length"
                  label="Load line length (metres)"
                  disabled={disabledForm}
                  required={
                    testimonialType === TestimonialType.DUAL ||
                    testimonialType === TestimonialType.DECK ||
                    testimonialType === TestimonialType.INTERIOR
                  }
                />
                <Input
                  name="vessel.grossTonnage"
                  label="Gross Tonnage"
                  disabled={disabledForm}
                  required
                />
                {testimonialType === TestimonialType.DUAL && (
                  <Input
                    name="vessel.mainEngineType"
                    label="Type of main engines"
                    disabled={disabledForm}
                  />
                )}
                {(testimonialType === TestimonialType.DUAL ||
                  testimonialType === TestimonialType.ENGINEERING) && (
                  <Input
                    name="vessel.mainEnginePower"
                    label="Main engines’ propulsion power (kW)"
                    disabled={disabledForm}
                    type="number"
                    required
                  />
                )}
              </div>

              <div>
                <h2 className="group__header">Onboard service</h2>
                <div className="sst-request__input">
                  <div className="sst-request__dates">
                    <DatePicker
                      name="dateFrom"
                      label="Date from"
                      disabled={disabledForm}
                      format={REQUEST_DATE_FORMAT}
                      required
                    />
                    <DatePicker
                      name="dateTo"
                      label="Date to"
                      disabled={disabledForm}
                      format={REQUEST_DATE_FORMAT}
                      required
                    />
                  </div>
                  <p className="sst-request__input-info">
                    The total sea service cannot be higher than the days
                    you&apos;ve been signed on.
                  </p>
                </div>
                <Input
                  name="actualDays"
                  label="Actual Days At Sea"
                  disabled={disabledForm}
                  type="number"
                />
                {(testimonialType === TestimonialType.ENGINEERING ||
                  testimonialType === TestimonialType.DUAL ||
                  testimonialType === TestimonialType.INTERIOR) && (
                  <Input
                    name="nauticalMiles"
                    label="Nautical Miles"
                    disabled={disabledForm}
                  />
                )}
                {testimonialType === TestimonialType.INTERIOR && (
                  <Input
                    name="daysGuests"
                    label="Days with guests on board"
                    disabled={disabledForm}
                    type="number"
                  />
                )}
                {testimonialType !== TestimonialType.DUAL && (
                  <Input
                    name="daysStandBy"
                    label="Days of Stand-by Service"
                    disabled={disabledForm}
                    type="number"
                  />
                )}
                <Input
                  name="daysShipyard"
                  label="Days of Shipyard Service"
                  disabled={disabledForm}
                  type="number"
                />
                {(testimonialType === TestimonialType.DUAL ||
                  testimonialType === TestimonialType.DECK) && (
                  <Input
                    name="daysWatchKeeping"
                    label="Watchkeeping Days"
                    disabled={disabledForm}
                    info="We cannot verify watchkeeping unless you are in possession of an OOW 3000 COC."
                  />
                )}
                {(testimonialType === TestimonialType.ENGINEERING ||
                  testimonialType === TestimonialType.DUAL) && (
                  <Input
                    name="daysSeagoingWatchKeeping"
                    label="Seagoing Watchkeeping Days"
                    disabled={disabledForm}
                    type="number"
                  />
                )}
                {(testimonialType === TestimonialType.ENGINEERING ||
                  testimonialType === TestimonialType.DUAL) && (
                  <Input
                    name="daysAdditionalWatchKeeping"
                    label="Additional Watchkeeping Days"
                    disabled={disabledForm}
                    type="number"
                  />
                )}
              </div>

              <div>
                <h2 className="group__header">
                  Leave of absence and/or rotation
                </h2>
                <Input
                  name="daysLeave"
                  label="Number of days leave of absence taken"
                  disabled={disabledForm}
                  required={
                    testimonialType === TestimonialType.INTERIOR ||
                    testimonialType === TestimonialType.DUAL ||
                    testimonialType === TestimonialType.ENGINEERING
                  }
                />
                {(testimonialType === TestimonialType.INTERIOR ||
                  testimonialType === TestimonialType.DUAL) && (
                  <Input
                    name="totalDays"
                    label="Total LoA and Total Sea Service (days)"
                    disabled={disabledForm}
                    type="number"
                  />
                )}
                <Input
                  name="rotationProgram"
                  label="Rotation program (if applicable)"
                  disabled={disabledForm}
                />
                <Select
                  name="areasCruised"
                  label="Areas cruised"
                  placeholder="Choose areas"
                  options={AREAS_OPTIONS}
                  disabled={disabledForm}
                  multiple
                  required
                />
                <LeaveDatesInput
                  name="leaveDates"
                  label="Dates of the leave of absence and/or rotation"
                  disabled={disabledForm}
                />
              </div>

              <TextArea
                name="additionalInformation"
                label="Additional information"
                disabled={disabledForm}
                placeholder="If you need to record nautical miles or other valuable information, enter it here; if not, leave this field empty."
              />
            </Card>

            <Card className="sst-request-detailed__card">
              <h2 className="card__header">Responsible person</h2>
              <Input
                name="responsiblePerson.firstName"
                label="Responsible person’s first name"
                disabled={disabledForm}
              />
              <Input
                name="responsiblePerson.middleName"
                label="Responsible person’s middle name"
                disabled={disabledForm}
              />
              <Input
                name="responsiblePerson.lastName"
                label="Responsible person’s last name"
                disabled={disabledForm}
              />
              <Select
                name="responsiblePerson.capacity"
                label="Responsible person’s capacity"
                placeholder="Choose capacity"
                options={RP_CAPACITY_OPTIONS}
                otherOption
                disabled={disabledForm}
              />
              <Input
                name="responsiblePerson.email"
                label="Responsible person’s email"
                type="email"
                disabled={disabledForm}
              />
              <Input
                name="responsiblePerson.cocNo"
                label="Responsible person’s CoC number"
                disabled={disabledForm}
              />
              <Input
                name="responsiblePerson.cocAuthority"
                label="CoC Issuing Authority"
                disabled={disabledForm}
              />
              <ResponsiblePersonPositionInput disabledForm={disabledForm} />
              <Button
                className="responsible-person-form__button"
                sx={{ backgroundColor: 'var(--ncap-50)' }}
                onClick={() => {
                  store.dispatch(changeModal(MODALS.VERIFY_RP_FORM));
                  store.dispatch(
                    setResponsiblePerson(
                      testimonial?.data.responsiblePerson ?? null
                    )
                  );
                }}
              >
                Validate RP
              </Button>
            </Card>
          </Form>
        </Formik>
      </div>
    </Page>
  );
};

export default SSTRequestDetailed;
