import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { addAuthorizationHeaderWithBearer } from 'src/shared/api/utils';
import { DefaultResponse } from 'src/shared/models';

import {
  CreateDocumentTemplateRequest,
  GetDocumentTemplateResponse,
} from '../models';

export const DOCUMENTS_API = 'DOCUMENTS_API';

const documentsApi = createApi({
  reducerPath: DOCUMENTS_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_TEMPLATES_URL,
      prepareHeaders: (headers) => addAuthorizationHeaderWithBearer(headers),
    }),
    {
      maxRetries: 0,
    }
  ),
  endpoints: (builder) => ({
    sendDocument: builder.query<void, FormData>({
      query: (request) => {
        return {
          method: 'POST',
          url: 'credential/navocerts/send-document-sync',
          body: request,
          formData: true,
        };
      },
    }),
    getDocument: builder.query<void, string>({
      query: (uid) => {
        return {
          method: 'GET',
          url: `${process.env.REACT_APP_DOCUMENT_URL}/document/${uid}`,
        };
      },
    }),
    createDocumentTemplate: builder.mutation<
      DefaultResponse,
      CreateDocumentTemplateRequest
    >({
      query: (request) => {
        return {
          method: 'POST',
          url: `${process.env.REACT_APP_DOCUMENT_URL}/document/template`,
          body: request,
        };
      },
    }),
    getDocumentTemplate: builder.query<GetDocumentTemplateResponse, string>({
      query: (uid) => {
        return {
          method: 'GET',
          url: `${process.env.REACT_APP_DOCUMENT_URL}/document/template/${uid}`,
        };
      },
    }),
  }),
});

export const {
  useLazySendDocumentQuery,
  useGetDocumentQuery,
  useCreateDocumentTemplateMutation,
  useLazyGetDocumentTemplateQuery,
} = documentsApi;

export const documentsMiddleware = documentsApi.middleware;

export default documentsApi.reducer;
