export interface Template {
  uid: string;
  name: string;
  description: string;
  image: string;
  renderParams: Record<string, CredentialTemplateField>;
  criteria?: string;
  tags?: string[] | null;
  type?: string;
  userId?: string;
  userLogin?: string;
  designTemplateUid: string;
}

export type GetTemplatesResponse = {
  data: Template[];
};

export interface CreateTemplateRequest {
  name: string;
  description: string;
  image: string;
}

export interface CredentialTemplateField {
  fieldLabel: string;
  fieldType: string;
}

export interface CreateCredentialTemplateRequest {
  name: string;
  image: string;
  description: string;
  type: string;
  criteria?: string;
  tags?: string;
  renderParams?: {
    [key: string]: CredentialTemplateField;
  };
  designTemplateUid: string;
}

export interface CreateTemplateForCompanyRequest
  extends CreateCredentialTemplateRequest {
  companyUid: string;
}

export interface UpdateTemplateRequest {
  id: string;
  name: string;
  description: string;
  image: string;
}

export interface CreateTemplateForUserRequest {
  templateUid: string;
  userUid: string;
}

export const enum LabelTypes {
  NUMBER = 'number',
  DATE = 'date',
  TEXT = 'text',
  FIXED_TEXT = 'fixedText',
  SHORT_TEXT = 'shortText',
  BOOLEAN = 'boolean',
  IMAGE = 'image',
}

export const TEMPLATE_FIELD_TYPES = {
  [LabelTypes.SHORT_TEXT]: 'text',
  [LabelTypes.TEXT]: 'text',
  [LabelTypes.FIXED_TEXT]: 'text',
  [LabelTypes.BOOLEAN]: 'text',
  [LabelTypes.DATE]: 'text',
  [LabelTypes.NUMBER]: 'text',
  [LabelTypes.IMAGE]: 'image',
};

export const RECIPIENT_INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  secondaryIdentifier: '',
  birthdate: '',
  country: '',
  countryId: '',
  issuedOn: '',
  externalIdentifier: '',
  expires: '',
};

export const DISPLAY_NAME_FIELDNAME = 'displayName';
