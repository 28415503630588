import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { addAuthorizationHeaderWithBearer } from 'src/shared/api/utils';

import {
  CreateCredentialRequest,
  GetCredentialsRequest,
  GetCredentialsResponse,
  NotifyCredential,
} from '../models';

export const CREDENTIALS_API = 'CREDENTIALS_API';

const prepareQueryParams = (request: GetCredentialsRequest) => {
  const search = request.searchString ?? '';
  const params = search
    ? {
        'recipientFirstName.contains': search,
        'recipientLastName.contains': search,
        'recipientEmail.contains': search,
        'templateName.contains': search,
        'identifier.contains': search,
      }
    : {};
  const pagination = request.page !== undefined &&
    request.size !== undefined && {
      page: request.page,
      size: request.size,
    };

  const resObject = {
    ...params,
    ...pagination,
  };

  return resObject;
};

const prepareSorting = (request: GetCredentialsRequest) => {
  const sorting = request.sorting?.[0];
  return sorting
    ? `&sort=${sorting?.id},${sorting?.desc ? 'desc' : 'asc'}`
    : '&sort=issuedOn,desc';
};

const credentialsApi = createApi({
  reducerPath: CREDENTIALS_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_TEMPLATES_URL,
      prepareHeaders: (headers) => addAuthorizationHeaderWithBearer(headers),
    }),
    {
      maxRetries: 0,
    }
  ),
  tagTypes: ['CREDENTIALS'],
  endpoints: (builder) => ({
    getCredentials: builder.query<
      GetCredentialsResponse,
      GetCredentialsRequest
    >({
      query: (request) => ({
        method: 'GET',
        url: `credential/credentials/issued?${prepareSorting(request)}`,
        params: {
          ...prepareQueryParams(request),
        },
      }),
      providesTags: ['CREDENTIALS'],
    }),
    getCredentialsCount: builder.query<number, GetCredentialsRequest>({
      query: (request) => ({
        method: 'GET',
        url: 'credentials/count',
        params: prepareQueryParams(request),
      }),
      providesTags: ['CREDENTIALS'],
    }),
    createCredential: builder.mutation<Credential, CreateCredentialRequest>({
      query: (request) => ({
        method: 'POST',
        url: `credential/credential-with-render`,
        body: request,
      }),
      invalidatesTags: ['CREDENTIALS'],
    }),
    revokeCredential: builder.mutation<void, string>({
      query: (id) => ({
        method: 'POST',
        url: `credential/revoke/${id}`,
        body: {
          revocationReason: '',
        },
      }),
      invalidatesTags: ['CREDENTIALS'],
    }),
    notifyCredential: builder.query<void, NotifyCredential>({
      query: (request) => ({
        method: 'POST',
        url: `credential/notify/credential`,
        body: request,
      }),
    }),
    batchSendCredentials: builder.mutation<void, string[]>({
      query: (ids: string[]) => ({
        method: 'POST',
        url: 'credential/send',
        body: {
          idArray: ids,
        },
      }),
    }),
  }),
});

export const {
  useGetCredentialsQuery,
  useGetCredentialsCountQuery,
  useCreateCredentialMutation,
  useRevokeCredentialMutation,
  useLazyNotifyCredentialQuery,
  useBatchSendCredentialsMutation,
} = credentialsApi;

export const credentialsMiddleware = credentialsApi.middleware;

export default credentialsApi.reducer;
