import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { IconButton, Popover, Tooltip } from '@mui/material';
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';

import { paths } from 'src/app/routes';
import Icon from 'src/shared/components/Icon/Icon';
import Tabs from 'src/shared/components/Tabs/Tabs';
import { IconType } from 'src/shared/components/Icon/IconType';
import SearchBar from 'src/shared/components/SearchBar/SearchBar';
import Dropdown, {
  DropdownElement,
} from 'src/shared/components/Dropdown/Dropdown';
import Card from 'src/shared/components/Card/Card';
import Table from 'src/shared/components/Table/Table';
import { DATE_WITHOUT_TIMEZONE } from 'src/shared/utils';
import Page from 'src/layouts/BaseLayout/components/Page/Page';

import { store } from 'src/features/store/store';
import { MODALS } from 'src/features/modal/models';
import { MobileUser } from 'src/features/companies/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { setUserProfile } from 'src/features/auth/slices/authSlice';
import { useCheckAuthorization, useCheckError } from 'src/features/hooks';
import { useGetMobileUsersQuery } from 'src/features/companies/api/companiesApi';
import AccountStatus from 'src/features/companies/components/AccountStatus/AccountStatus';

import './MobileUsers.scss';

const SIZE = 10;

type MobileUsersTableProps = {
  email: string;
  createdAt: string;
  signedIn: string;
  uid: string;
  emailVerified: boolean;
};

const MobileUsers = () => {
  const [search, setSearch] = useState<string>('');
  const [users, setUsers] = useState<MobileUser[]>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: SIZE,
  });
  const { data: mobileUsers, isError } = useGetMobileUsersQuery({
    pageNumber: pagination.pageIndex,
    pageSize: pagination.pageSize,
  });
  const [currentTab, setCurrentTab] = useState<number>(0);

  const dropdownItems: DropdownElement<MobileUser>[] = useMemo(() => {
    return [
      {
        text: 'Reset Password',
        onClick: (user: MobileUser) => {
          store.dispatch(
            setUserProfile({
              userUid: user.uid,
              email: user.email,
            })
          );
          store.dispatch(changeModal(MODALS.RESET_PASSWORD));
        },
      },
      {
        text: 'Activate Account',
        onClick: (user: MobileUser) => {
          store.dispatch(
            setUserProfile({
              userUid: user.uid,
              email: user.email,
            })
          );
          store.dispatch(changeModal(MODALS.ACTIVATE_ACCOUNT));
        },
        disabled: (user: MobileUser) => user.emailVerified,
      },
    ];
  }, []);

  const TABS = useMemo(
    () => [
      {
        label: 'Mobile Users',
        path: paths.mobileUsers,
      },
    ],
    []
  );
  const handleTabClick = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  useCheckError(isError, 'Error loading Mobile users');

  useCheckAuthorization();

  const filterMobileUsers = useCallback(
    () =>
      mobileUsers
        ? mobileUsers?.data.filter((user) =>
            user.email.toLowerCase().includes(search.toLowerCase())
          )
        : [],
    [search, mobileUsers]
  );

  useEffect(() => {
    setUsers(filterMobileUsers);
  }, [filterMobileUsers, search]);

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'Created',
        accessorKey: 'createdAt',
        Cell: ({ cell }) =>
          dayjs(cell.getValue<string>()).format(DATE_WITHOUT_TIMEZONE),
        size: 80,
      },
      {
        header: 'Signed In',
        accessorKey: 'signedIn',
        Cell: ({ cell }) =>
          dayjs(cell.getValue<string>()).format(DATE_WITHOUT_TIMEZONE),
        size: 80,
      },
      {
        header: 'User UUID',
        accessorKey: 'uid',
      },
      {
        header: '',
        id: 'copyUid',
        accessorKey: 'uid',
        Cell: ({ cell }) => (
          <Tooltip title="Copy UUID">
            <IconButton
              onClick={() =>
                navigator.clipboard.writeText(cell.getValue<string>())
              }
            >
              <Icon icon={IconType.Copy} />
            </IconButton>
          </Tooltip>
        ),
        size: 20,
      },
      {
        header: 'Account Status',
        accessorKey: 'emailVerified',
        Cell: ({ cell }) => (
          <AccountStatus verified={cell.getValue<boolean>()} />
        ),
        size: 120,
      },
      {
        header: '',
        id: 'actions',
        size: 50,
        maxSize: 50,
        Cell: ({ row }) => {
          const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
            null
          );
          const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          };
          const dropdownIsOpen = Boolean(anchorEl);

          const handleClose = () => {
            setAnchorEl(null);
          };

          return (
            <>
              <IconButton onClick={handleClick} className="button_row-actions">
                <Icon icon={IconType.Dots} />
              </IconButton>
              <Popover
                open={dropdownIsOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Dropdown<MobileUser>
                  elements={dropdownItems}
                  element={row.original as MobileUser}
                  callbackOnClick={handleClose}
                />
              </Popover>
            </>
          );
        },
      },
    ],
    [dropdownItems]
  );

  return (
    <Page>
      <div className="mobile-users">
        <div className="mobile-users__header">
          <h1>Users</h1>
        </div>
        <SearchBar onChange={(e) => setSearch(e.target.value)} />

        <Card className="mobile-users__table-container">
          <Tabs value={currentTab} values={TABS} onChange={handleTabClick} />
          <Table<MobileUsersTableProps>
            columns={columns}
            data={users}
            enableSorting
            // enableRowSelection
            manualPagination
            onPaginationChange={setPagination}
            rowCount={mobileUsers?.size ?? 0}
            state={{
              pagination,
            }}
          />
        </Card>
      </div>
    </Page>
  );
};

export default MobileUsers;
