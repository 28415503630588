export const routes = {
  root: {
    index: '/',
    dashboard: 'dashboard',
    requests: 'requests',
    campaigns: 'campaigns',
    cocRequests: 'coc-requests',
    cocPayments: 'coc-payments',
    credentials: 'credentials',
    responsiblePersons: 'responsible-persons',
    mobileUsers: 'mobile-users',
    settings: 'settings',
    notifications: 'notifications',
    guest: 'guest',
  },
  auth: {
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
    resetPasswordConfirm: 'account/reset-password/confirm',
  },
  settings: {
    index: '/settings',
    templates: 'templates',
    issuers: 'issuers',
    admins: 'admins',
    templateDesigner: 'template-design',
    companies: 'companies',
    createTemplate: 'create-template',
    editTemplate: 'edit-template',
    viewTemplate: 'template',
    createIssuer: 'create-issuer',
    editIssuer: 'edit-issuer',
    viewIssuer: 'issuer',
  },
  credentials: {
    index: '/credentials',
    createCredential: 'create-credential',
  },
  campaigns: {
    index: '/campaigns',
    createCampaign: 'create-campaign',
  },
  guest: {
    uploadDocuments: 'upload-documents',
  },
  mobile: {
    index: 'mobile',
    confirmPrimaryEmail: 'confirm-primary-email',
    confirmSecondaryEmail: 'confirm-secondary-email',
  },
  companies: {
    issuers: 'issuers',
    templates: 'templates',
  },
  statusApproved: 'approved',
  statusValidated: 'validated',
  id: ':id',
  postfix: ':postfix',
};

export const paths = {
  signIn: routes.root.index,
  dashboard: `${routes.root.index}${routes.root.dashboard}`,
  requests: `${routes.root.index}${routes.root.requests}`,
  requestsValidated: `${routes.root.index}${routes.root.requests}?status=${routes.statusValidated}`,
  requestsApproved: `${routes.root.index}${routes.root.requests}?status=${routes.statusApproved}`,
  cocRequests: `${routes.root.index}${routes.root.cocRequests}`,
  cocRequestsApproved: `${routes.root.index}${routes.root.cocRequests}?status=${routes.statusApproved}`,
  cocPayments: `${routes.root.index}${routes.root.cocPayments}`,
  credentials: `${routes.root.index}${routes.root.credentials}`,
  campaigns: `${routes.root.index}${routes.root.campaigns}`,
  responsiblePersons: `${routes.root.index}${routes.root.responsiblePersons}`,
  mobileUsers: `${routes.root.index}${routes.root.mobileUsers}`,
  settings: `${routes.root.index}${routes.root.settings}`,
  notifications: `${routes.root.index}${routes.root.notifications}`,

  forgotPassword: `${routes.root.index}${routes.auth.forgotPassword}`,
  resetPassword: `${routes.root.index}${routes.auth.resetPassword}`,
  resetPasswordConfirm: `${routes.root.index}${routes.auth.resetPasswordConfirm}`,

  settingsTemplates: `${routes.settings.index}/${routes.settings.templates}`,
  settingsIssuers: `${routes.settings.index}/${routes.settings.issuers}`,
  settingsAdmins: `${routes.settings.index}/${routes.settings.admins}`,
  settingsTemplateDesigner: `${routes.settings.index}/${routes.settings.templateDesigner}`,
  companyById: (id: string, postfix?: string) =>
    `${routes.settings.index}/${routes.settings.admins}/${routes.settings.companies}/${id}${postfix ? '?tab=' + postfix : ''}`,

  createTemplate: `${routes.settings.index}/${routes.settings.createTemplate}`,
  editTemplate: (id: string) =>
    `${routes.settings.index}/${routes.settings.editTemplate}/${id}`,
  viewTemplate: (id: string) =>
    `${routes.settings.index}/${routes.settings.viewTemplate}/${id}`,

  createIssuer: `${routes.settings.index}/${routes.settings.createIssuer}`,
  editIssuer: (id: string) =>
    `${routes.settings.index}/${routes.settings.editIssuer}/${id}`,
  viewIssuer: (id: string) =>
    `${routes.settings.index}/${routes.settings.viewIssuer}/${id}`,

  createCredential: `${routes.credentials.index}/${routes.credentials.createCredential}`,
  createCampaign: `${routes.campaigns.index}/${routes.campaigns.createCampaign}`,

  requestById: (id: string) =>
    `${routes.root.index}${routes.root.requests}/${id}`,
  cocRequestById: (id: string) =>
    `${routes.root.index}${routes.root.cocRequests}/${id}`,
  campaignById: (id: string) =>
    `${routes.root.index}${routes.root.campaigns}/${id}`,
  uploadGUESTDocuments: (id: string) =>
    `${routes.root.index}${routes.root.guest}/${id}/${routes.guest.uploadDocuments}`,
};
