/* eslint-disable prettier/prettier */
import authReducer, { AUTH } from 'src/features/auth/slices/authSlice';
import modalReducer, { MODAL } from 'src/features/modal/slices/modalSlice';
import campaignsReducer, { CAMPAIGNS } from '../campaigns/slices/campaignsSlice';
import companiesReducer, { COMPANIES } from 'src/features/companies/slices/companiesSlice';
import templatesReducer, { TEMPLATES } from 'src/features/templates/slices/templatesSlice';
import credentialsReducer, { CREDENTIALS } from 'src/features/credentials/slices/credentialsSlice';
import issuersReducer, { ISSUERS } from 'src/features/issuers/slices/issuersSlice';
import cocRequestsReducer, { COC_REQUEST } from 'src/features/cocRequests/slices/cocRequestsSlice';
import testimonialReducer, { TESTIMONIAL } from 'src/features/testimonials/slices/testimonialSlice';
import responsiblePersonsReducer, { RESPONSIBLE_PERSONS } from 'src/features/responsiblePersons/slices/responsiblePersonsSlice';

import authApiReducer, { AUTH_API } from 'src/features/auth/api/authApi';
import paymentApiReducer, { PAYMENT_API } from 'src/features/payment/api/paymentApi';
import documentsApiReducer, { DOCUMENTS_API } from 'src/features/documents/api/documentsApi';
import templatesApiReducer, { TEMPLATES_API } from 'src/features/templates/api/templatesApi';
import analyticsApiReducer, { ANALYTICS_API } from 'src/features/analytics/api/analyticsApi';
import companiesApiReducer, { COMPANIES_API } from 'src/features/companies/api/companiesApi';
import campaignsApiReducer, { CAMPAIGNS_API } from 'src/features/campaigns/api/campaignsApi';
import issuersApiReducer, { ISSUERS_API } from 'src/features/issuers/api/issuersApi';
import credentialsApiReducer, { CREDENTIALS_API } from 'src/features/credentials/api/credentialsApi';
import testimonialsApiReducer, { TESTIMONIALS_API } from 'src/features/testimonials/api/testimonialsApi';
import cocRequestsApiReducer, { GUEST_APPLICATION_API } from 'src/features/cocRequests/api/cocRequestsApi';
import notificationsApiReducer, { NOTIFICATIONS_API } from 'src/features/notifications/api/notificationsApi';
import responsiblePersonsApiReducer, { RESPONSIBLE_PERSONS_API } from 'src/features/responsiblePersons/api/responsiblePersonsApi';
import mobileApiReducer, { MOBILE_API } from 'src/features/mobile/api/mobileApi';

const rootReducer = {
  [AUTH]: authReducer,
  [MODAL]: modalReducer,
  [TEMPLATES]: templatesReducer,
  [CAMPAIGNS]: campaignsReducer,
  [COMPANIES]: companiesReducer,
  [TESTIMONIAL]: testimonialReducer,
  [COC_REQUEST]: cocRequestsReducer,
  [ISSUERS]: issuersReducer,
  [CREDENTIALS]: credentialsReducer,
  [RESPONSIBLE_PERSONS]: responsiblePersonsReducer,

  [AUTH_API]: authApiReducer,
  [PAYMENT_API]: paymentApiReducer,
  [DOCUMENTS_API]: documentsApiReducer,
  [TEMPLATES_API]: templatesApiReducer,
  [ANALYTICS_API]: analyticsApiReducer,
  [ISSUERS_API]: issuersApiReducer,
  [CREDENTIALS_API]: credentialsApiReducer,
  [COMPANIES_API]: companiesApiReducer,
  [CAMPAIGNS_API]: campaignsApiReducer,
  [TESTIMONIALS_API]: testimonialsApiReducer,
  [GUEST_APPLICATION_API]: cocRequestsApiReducer,
  [NOTIFICATIONS_API]: notificationsApiReducer,
  [RESPONSIBLE_PERSONS_API]: responsiblePersonsApiReducer,
  [MOBILE_API]: mobileApiReducer,
};

export default rootReducer;
