import { useSelector } from 'react-redux';

import { MODAL as MODAL } from 'src/features/modal/slices/modalSlice';
import { State } from 'src/features/store/store';

import { MODALS } from './models';
import Logout from './components/Logout/Logout';
import RejectCoC from './components/RejectCoC/RejectCoC';
import ApproveCoC from './components/ApproveCoC/ApproveCoC';
import ApproveSST from './components/ApproveSST/ApproveSST';
import ReturnToRP from './components/ReturnToRP/ReturnToRP';
import AddCompany from './components/AddCompany/AddCompany';
import AddRP, { AddRPTypes } from './components/AddRP/AddRP';
import ValidateSST from './components/ValidateSST/ValidateSST';
import CompanyUser from './components/CompanyUser/CompanyUser';
import ResetPassword from './components/ResetPassword/ResetPassword';
import ActivateAccount from './components/ActivateAccount/ActivateAccount';
import CampaignPreview from './components/CampaignPreview/CampaignPreview';
import ReturnToSeafarer from './components/ReturnToSeafarer/ReturnToSeafarer';
import ImportSpreadsheet from './components/ImportSpreadsheet/ImportSpreadsheet';
import CommunicationTrail from './components/CommunicationTrail/CommunicationTrail';
import ReturnToUnvalidated from './components/ReturnToUnvalidated/ReturnToUnvalidated';
import CredentialsCreation from './components/CredentialsCreation/CredentialsCreation';
import CampaignSentSuccess from './components/CampaignSentSuccess/CampaignSentSuccess';
import ReturnCoCToSeafarer from './components/ReturnCoCToSeafarer/ReturnCoCToSeafarer';
import AddCredentialRecipient, {
  AddRecipientTypes,
} from './components/AddCredentialRecipient/AddCredentialRecipient';

const modalMap = {
  [MODALS.APPROVE_SST]: <ApproveSST />,
  [MODALS.APPROVE_COC]: <ApproveCoC />,
  [MODALS.REJECT_COC]: <RejectCoC />,
  [MODALS.VALIDATE_SST]: <ValidateSST />,
  [MODALS.RETURN_TO_RP]: <ReturnToRP />,
  [MODALS.RETURN_TO_SEAFARER]: <ReturnToSeafarer />,
  [MODALS.RETURN_COC_TO_SEAFARER]: <ReturnCoCToSeafarer />,
  [MODALS.RETURN_TO_UNVALIDATED]: <ReturnToUnvalidated />,
  [MODALS.ADD_RP_FORM]: <AddRP />,
  [MODALS.EDIT_RP_FORM]: <AddRP type={AddRPTypes.EDIT} />,
  [MODALS.VERIFY_RP_FORM]: <AddRP type={AddRPTypes.PREFILL} />,
  [MODALS.ADD_RECIPIENT_FORM]: (
    <AddCredentialRecipient type={AddRecipientTypes.CREATE} />
  ),
  [MODALS.EDIT_RECIPIENT_FORM]: (
    <AddCredentialRecipient type={AddRecipientTypes.EDIT} />
  ),
  [MODALS.RESET_PASSWORD]: <ResetPassword />,
  [MODALS.ACTIVATE_ACCOUNT]: <ActivateAccount />,
  [MODALS.IMPORT_SPREADSHEET]: <ImportSpreadsheet />,
  [MODALS.ADD_COMPANY]: <AddCompany />,
  [MODALS.ADD_COMPANY_USER]: <CompanyUser />,
  [MODALS.EDIT_COMPANY_USER]: <CompanyUser type="EDIT" />,
  [MODALS.CAMPAIGN_PREVIEW]: <CampaignPreview />,
  [MODALS.CAMPAIGN_SUCCESS]: <CampaignSentSuccess />,
  [MODALS.CREDENTIALS_CREATION]: <CredentialsCreation />,
  [MODALS.COMMUNICATION_TRAIL]: <CommunicationTrail />,
  [MODALS.LOGOUT]: <Logout />,
};

export const ModalBase = () => {
  const openedModal = useSelector((s: State) => s[MODAL].openedModal);
  return openedModal !== null ? modalMap[openedModal] : null;
};
