import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { addAuthorizationHeaderWithBearer } from 'src/shared/api/utils';
import { DefaultResponse } from 'src/shared/models';

import {
  CreateCompanyRequest,
  CreateUserProfileRequest,
  GetCompaniesRequest,
  GetCompanyUsersRequest,
  GetMobileUsersRequest,
  GetMobileUsersResponse,
  UpdateUserProfileRequest,
} from '../models';

export const COMPANIES_API = 'COMPANIES_API';

const companiesApi = createApi({
  reducerPath: COMPANIES_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_API_URL,
      prepareHeaders: (headers) => addAuthorizationHeaderWithBearer(headers),
    }),
    {
      maxRetries: 0,
    }
  ),
  tagTypes: ['COMPANIES', 'MOBILE_PROFILE', 'USERS'],
  endpoints: (builder) => ({
    getCompanies: builder.query<GetCompaniesRequest, void>({
      query: () => {
        return {
          method: 'GET',
          url: 'user-profile/company',
        };
      },
      providesTags: ['COMPANIES'],
    }),
    getCompanyUsers: builder.query<GetCompanyUsersRequest, string>({
      query: (companyUid) => {
        return {
          method: 'GET',
          url: `user-profile/company/${companyUid}/users`,
        };
      },
      providesTags: ['USERS'],
    }),
    createCompany: builder.mutation<DefaultResponse, CreateCompanyRequest>({
      query: (request) => {
        return {
          method: 'POST',
          url: `user-profile/company`,
          body: request,
        };
      },
      invalidatesTags: ['COMPANIES'],
    }),
    createUserProfile: builder.mutation<
      DefaultResponse,
      CreateUserProfileRequest
    >({
      query: (request) => ({
        method: 'POST',
        body: request,
        url: 'user-profile',
      }),
      invalidatesTags: ['USERS'],
    }),
    updateUserProfile: builder.mutation<
      DefaultResponse,
      UpdateUserProfileRequest
    >({
      query: (request) => ({
        method: 'PUT',
        body: request,
        url: `user-profile/admin/users/${request.userUid}`,
      }),
      invalidatesTags: ['USERS'],
    }),
    getMobileUsers: builder.query<
      GetMobileUsersResponse,
      GetMobileUsersRequest
    >({
      query: (request) => ({
        method: 'GET',
        url: 'user-profile',
        params: request,
      }),
      providesTags: ['MOBILE_PROFILE'],
    }),
    sendResetPasswordLink: builder.mutation<void, string>({
      query: (userUid) => ({
        method: 'POST',
        url: `user-profile/mobile/${userUid}/reset-password`,
      }),
      invalidatesTags: ['MOBILE_PROFILE'],
    }),
    activateAccount: builder.mutation<void, string>({
      query: (userUid) => ({
        method: 'POST',
        url: `user-profile/mobile/${userUid}/activate`,
      }),
      invalidatesTags: ['MOBILE_PROFILE'],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useLazyGetCompanyUsersQuery,
  useCreateCompanyMutation,
  useCreateUserProfileMutation,
  useUpdateUserProfileMutation,
  useGetMobileUsersQuery,
  useSendResetPasswordLinkMutation,
  useActivateAccountMutation,
} = companiesApi;

export const companiesMiddleware = companiesApi.middleware;

export default companiesApi.reducer;
