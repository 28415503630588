import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { addAuthorizationHeaderWithBearer } from 'src/shared/api/utils';

import {
  SubscribeToNotificationsRequest,
  SubscribeToNotificationsResponse,
} from '../models';

export const NOTIFICATIONS_API = 'NOTIFICATIONS_API';

const notificationsApi = createApi({
  reducerPath: NOTIFICATIONS_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_API_URL,
      prepareHeaders: (headers) => addAuthorizationHeaderWithBearer(headers),
    }),
    { maxRetries: 0 }
  ),
  endpoints: (builder) => ({
    subscribeToNotifications: builder.query<
      SubscribeToNotificationsResponse,
      SubscribeToNotificationsRequest
    >({
      query: (request) => ({
        method: 'POST',
        url: `notifications/subscribe?topic=${request.topic}`,
        body: {
          deviceToken: request.deviceToken,
        },
      }),
    }),
    unsubscribeFromNotifications: builder.query<
      SubscribeToNotificationsResponse,
      SubscribeToNotificationsRequest
    >({
      query: (request) => ({
        method: 'POST',
        url: `notifications/unsubscribe?topic=${request.topic}`,
        body: {
          deviceToken: request.deviceToken,
        },
      }),
    }),
  }),
});

export const {
  useLazySubscribeToNotificationsQuery,
  useLazyUnsubscribeFromNotificationsQuery,
} = notificationsApi;

export const notificationsMiddleware = notificationsApi.middleware;

export default notificationsApi.reducer;
