import { Outlet } from 'react-router-dom';

import { ReactComponent as Logo } from './images/logo.svg';
import { ReactComponent as NavozymeLogo } from './images/logo-black.svg';

import './DocumentsUploadLayout.scss';

const DocumentsUploadLayout = () => {
  return (
    <div className="documents-upload-layout">
      <header>
        <div className="documents-upload-layout__header-block_first">
          <div>
            <Logo className="documents-upload-layout__logo" />
          </div>
          Encrypted document upload environment
        </div>
        <div className="documents-upload-layout__header-block_second">
          Powered by
          <NavozymeLogo className="documents-upload-layout__navozyme-logo" />
        </div>
      </header>
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default DocumentsUploadLayout;
