import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { Button, Divider, IconButton, Tooltip } from '@mui/material';
import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { MRT_ColumnDef } from 'material-react-table';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { paths, routes } from 'src/app/routes';
import Icon from 'src/shared/components/Icon/Icon';
import Tabs from 'src/shared/components/Tabs/Tabs';
import Table from 'src/shared/components/Table/Table';
import Input from 'src/shared/components/Input/Input';
import Spinner from 'src/shared/components/Spinner/Spinner';
import { IconType } from 'src/shared/components/Icon/IconType';
import { REQUEST_DATE_FORMAT, DATE_WITHOUT_TIMEZONE } from 'src/shared/utils';

import { State, store } from 'src/features/store/store';
import { useCheckError } from 'src/features/hooks';
import { MODALS } from 'src/features/modal/models';
import { UserRoles } from 'src/features/auth/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { setIssuer } from 'src/features/issuers/slices/issuersSlice';
import { CompanyUser } from 'src/features/companies/models';
import { setTemplate } from 'src/features/templates/slices/templatesSlice';
import { useLazyGetIssuersByCompanyQuery } from 'src/features/issuers/api/issuersApi';
import TemplatesList from 'src/features/templates/components/TemplatesList/TemplatesList';
import { useLazyGetTemplatesByCompanyQuery } from 'src/features/templates/api/templatesApi';
import { useLazyGetCompanyUsersQuery } from 'src/features/companies/api/companiesApi';
import {
  COMPANIES,
  setCompanyUser,
} from 'src/features/companies/slices/companiesSlice';

import { SettingsContext } from '../../SettingsContext';

const TABS_PREFIX = {
  [routes.companies.issuers]: 1,
  [routes.companies.templates]: 2,
};

const CompanyInfo = () => {
  const { id } = useParams();
  const searchParams = useSearchParams();
  const tab = searchParams[0].get('tab');
  const navigate = useNavigate();
  const settingsContext = useContext(SettingsContext);
  const currentCompany = useSelector((s: State) => s[COMPANIES].company);
  const [currentTab, setCurrentTab] = useState<number>(
    tab && TABS_PREFIX[tab] ? TABS_PREFIX[tab] : 0
  );
  const handleTabClick = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const [getCompanyUsers, companyUsers] = useLazyGetCompanyUsersQuery();
  const [getCompanyIssuers, companyIssuers] = useLazyGetIssuersByCompanyQuery();
  const [getCompanyTemplates, companyTemplates] =
    useLazyGetTemplatesByCompanyQuery();
  useCheckError(companyUsers.isError, 'Error loading company users');
  useCheckError(companyIssuers.isError, 'Error loading company issuers');
  useCheckError(companyTemplates.isError, 'Error loading company templates');

  useEffect(() => {
    if (id && companyUsers.isUninitialized) {
      getCompanyUsers(id);
    }
  }, [companyUsers.isUninitialized, getCompanyUsers, id]);

  useEffect(() => {
    if (id && companyIssuers.isUninitialized && currentTab === 1) {
      getCompanyIssuers(id);
    }
  }, [companyIssuers.isUninitialized, currentTab, getCompanyIssuers, id]);

  useEffect(() => {
    if (id && companyTemplates.isUninitialized && currentTab === 2) {
      getCompanyTemplates(id);
    }
  }, [companyTemplates.isUninitialized, currentTab, getCompanyTemplates, id]);

  const issuerActions = useMemo<(id: string, name?: string) => ReactElement[]>(
    () => (id) => [
      <Button
        key={0}
        className="template-preview__button"
        onClick={() => {
          const issuer = companyIssuers?.data?.data.find(
            (elem) => elem.uid === id
          );
          store.dispatch(setIssuer(issuer ?? null));
          settingsContext?.setPrevPage('Company');
          navigate(paths.viewIssuer(id));
        }}
      >
        Open
      </Button>,
    ],
    [companyIssuers?.data?.data, navigate, settingsContext]
  );

  const templateActions = useMemo<
    (id: string, name?: string) => ReactElement[]
  >(
    () => (id) => [
      <Button
        key={0}
        className="template-preview__button"
        onClick={() => {
          const template = companyTemplates?.data?.data.find(
            (elem) => elem.uid === id
          );
          store.dispatch(setTemplate(template ?? null));
          settingsContext?.setPrevPage('Company');
          navigate(paths.viewTemplate(id));
        }}
      >
        Open
      </Button>,
    ],
    [companyTemplates?.data?.data, navigate, settingsContext]
  );

  const usersColumns: MRT_ColumnDef[] = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'First name',
        accessorKey: 'firstName',
      },
      {
        header: 'Last name',
        accessorKey: 'lastName',
      },
      {
        header: 'Email',
        accessorKey: 'email',
        size: 100,
      },
      {
        header: 'Joined at',
        accessorKey: 'createdAt',
        size: 100,
        Cell: ({ cell }) =>
          cell.getValue<string>()
            ? dayjs(cell.getValue<string>(), REQUEST_DATE_FORMAT).format(
                DATE_WITHOUT_TIMEZONE
              )
            : '',
      },
      {
        header: 'Permissions',
        accessorKey: 'roles',
        Cell: ({ cell }) => {
          return (
            <div className="settings-users__permissions">
              {cell.getValue<UserRoles[]>().map((role, key) => (
                <span className="settings-users-permissions__role" key={key}>
                  {role}
                </span>
              ))}
            </div>
          );
        },
      },
      {
        header: '',
        id: 'edit',
        accessorKey: 'uid',
        Cell: ({ cell }) => (
          <Tooltip title="Edit user">
            <IconButton
              onClick={() => {
                store.dispatch(
                  setCompanyUser(cell.row.original as CompanyUser)
                );
                store.dispatch(changeModal(MODALS.EDIT_COMPANY_USER));
              }}
            >
              <Icon icon={IconType.Pencil} />
            </IconButton>
          </Tooltip>
        ),
        size: 20,
      },
    ],
    []
  );

  const TABS = useMemo(() => {
    return [
      {
        label: 'Users',
        component: (
          <Table<CompanyUser>
            columns={usersColumns}
            data={companyUsers.data?.data ?? []}
            enableSorting
          />
        ),
        path: paths.companyById(id ?? ''),
      },
      {
        label: 'Issuers',
        component: companyIssuers.isLoading ? (
          <Spinner />
        ) : (
          <TemplatesList
            data={companyIssuers?.data?.data ?? []}
            actionButtons={issuerActions}
            className="settings-issuers__list"
            noDataElement={
              <p className="settings-templates-list__no-data-message">
                There are no issuers created for this company
              </p>
            }
          />
        ),
        path: paths.companyById(id ?? '', routes.companies.issuers),
      },
      {
        label: 'Templates',
        component: companyTemplates.isLoading ? (
          <Spinner />
        ) : (
          <TemplatesList
            data={companyTemplates.data?.data ?? []}
            actionButtons={templateActions}
            className="settings-templates__list"
            noDataElement={
              <p className="settings-templates-list__no-data-message">
                There are no templates created for this company
              </p>
            }
          />
        ),
        path: paths.companyById(id ?? '', routes.companies.templates),
      },
    ];
  }, [
    companyIssuers?.data?.data,
    companyIssuers.isLoading,
    companyTemplates.data?.data,
    companyTemplates.isLoading,
    companyUsers.data?.data,
    id,
    issuerActions,
    templateActions,
    usersColumns,
  ]);

  const BUTTONS = useMemo(
    () => [
      <Button
        key={`${id ?? 'button'}-1`}
        variant="contained"
        startIcon={<Icon icon={IconType.Plus} />}
        onClick={() => store.dispatch(changeModal(MODALS.ADD_COMPANY_USER))}
      >
        Add user
      </Button>,
      <Button
        key={`${id ?? 'button'}-2`}
        variant="contained"
        startIcon={<Icon icon={IconType.Plus} />}
        onClick={() => {
          settingsContext?.setPrevPage('Company');
          navigate(paths.createIssuer);
        }}
      >
        Add issuer
      </Button>,
      <Button
        key={`${id ?? 'button'}-3`}
        variant="contained"
        startIcon={<Icon icon={IconType.Plus} />}
        onClick={() => {
          settingsContext?.setPrevPage('Company');
          navigate(paths.createTemplate);
        }}
      >
        Add template
      </Button>,
    ],
    [id, navigate, settingsContext]
  );

  return (
    <>
      <h2 className="settings-company__header">Company</h2>
      <div className="settings-company__company-info">
        <Formik
          initialValues={currentCompany ?? {}}
          onSubmit={() => console.log('submit')}
        >
          <Form>
            <Input name="companyName" label="Name" disabled />
            <Input name="email" label="Email" disabled />
            <Input name="vatNumber" label="VAT" disabled />
            <Input name="city" label="City (Country Code)" disabled />
            <Input name="createdAt" label="Created Date" disabled />
          </Form>
        </Formik>
      </div>
      <Divider className="settings-company__divider" />
      <div className="settings-users__header">
        <Tabs value={currentTab} values={TABS} onChange={handleTabClick} />
        {BUTTONS[currentTab]}
      </div>
      {TABS[currentTab].component}
    </>
  );
};

export default CompanyInfo;
