import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  PolarAngleAxis,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
} from 'recharts';

import Card from 'src/shared/components/Card/Card';
import Page from 'src/layouts/BaseLayout/components/Page/Page';

import { useCheckAuthorization, useCheckError } from 'src/features/hooks';
import {
  useGetTemplateNamesQuery,
  useGetWalletDownloadsTotalQuery,
  useGetTemplateBadgesQuery,
} from 'src/features/analytics/api/analyticsApi';
import { State } from 'src/features/store/store';
import { UserRoles } from 'src/features/auth/models';
import { rolesEquals } from 'src/features/auth/utils';
import { AUTH } from 'src/features/auth/slices/authSlice';
import {
  GetTotalsResponse,
  TotalDownloads,
} from 'src/features/analytics/models';

import TimeChart from './components/TimeChart/TimeChart';
import TypeChart from './components/TypeChart/TypeChart';
import ChartFilterButtons, {
  BUTTONS_TYPE,
} from './components/ChartFilterButtons/ChartFilterButtons';
import DownloadsChart from './components/DownloadsChart/DownloadsChart';
import ApprovedTestimonialsChart from './components/ApprovedTestimonialsChart/ApprovedTestimonialsChart';

import './Dashboard.scss';

enum TABS {
  TIME = 'Time',
  TYPE = 'Type',
  BATCH = 'Batch',
}

const Dashboard = () => {
  const userRoles = useSelector((s: State) => s[AUTH].roles);
  const [selectedTab, setSelectedTab] = useState<TABS>(TABS.TIME);
  const { data: templateNames, isError } = useGetTemplateNamesQuery();
  const { data: templateBadges } = useGetTemplateBadgesQuery();
  const { data: walletDownloadsTotal } = useGetWalletDownloadsTotalQuery();
  useCheckError(isError, 'Error loading Template names');

  useCheckAuthorization();

  const certificatesNumber = templateNames?.data.reduce(
    (accumulator, currentValue) => accumulator + currentValue.count,
    0
  );

  const downloadsPercentage = (
    walletDownloadsTotal as unknown as GetTotalsResponse
  )?.TOTALS
    ? Math.round(
        (walletDownloadsTotal as unknown as GetTotalsResponse)?.TOTALS[1] ?? 0
      )
    : Math.round(
        (walletDownloadsTotal as unknown as { data: TotalDownloads })?.data
          .total_percentage ?? 0
      );

  const FILTERS = [
    {
      name: TABS.TIME,
      onClick: () => setSelectedTab(TABS.TIME),
      selected: selectedTab === TABS.TIME,
    },
    {
      name: TABS.TYPE,
      onClick: () => setSelectedTab(TABS.TYPE),
      selected: selectedTab === TABS.TYPE,
    },
    {
      name: TABS.BATCH,
      onClick: () => setSelectedTab(TABS.BATCH),
      selected: selectedTab === TABS.BATCH,
    },
  ];

  const CHARTS = new Map<TABS, JSX.Element>([
    [TABS.TIME, <TimeChart key="time-chart" />],
    [
      TABS.TYPE,
      <TypeChart
        key="type-chart"
        types={
          templateNames?.data.map((name) => {
            return { id: name.id, name: name.name };
          }) ?? []
        }
      />,
    ],
    [
      TABS.BATCH,
      <TypeChart
        key="batch-chart"
        batch
        types={
          templateBadges?.data.TEMPLATENAMES.map((name) => {
            return {
              id: name[1] as string,
              name: name[1] as string,
            };
          }) ?? []
        }
      />,
    ],
  ]);

  return (
    <Page>
      <div className="dashboard">
        <h1>Dashboard</h1>
        <div className="dashboard-content">
          <Card header="Issued certificates" className="dashboard-card">
            <div className="chart">
              <div className="chart__header">
                <div className="chart__name-and-filters">
                  <ChartFilterButtons
                    filters={FILTERS}
                    type={BUTTONS_TYPE.CONTAINED}
                    className="chart__filters"
                  />
                </div>
                <div className="count-box">
                  <div className="count-box__number">
                    {certificatesNumber ?? 0}
                  </div>
                  <div className="count-box__label">Certificates</div>
                </div>
              </div>
              <div className="chart__body">{CHARTS.get(selectedTab)}</div>
            </div>
          </Card>
          {!rolesEquals(userRoles, [UserRoles.PYA]) &&
            !rolesEquals(userRoles, [UserRoles.PYA_ADMIN]) && (
              <Card
                header="Wallet Downloads (Time Interval)"
                className="dashboard-card"
              >
                <DownloadsChart />
              </Card>
            )}
          {userRoles?.includes(UserRoles.PYA) ||
          userRoles?.includes(UserRoles.PYA_ADMIN) ? (
            <Card
              header="Sea Service Testimonials approved"
              className="dashboard-card"
            >
              <ApprovedTestimonialsChart />
            </Card>
          ) : null}
          {!rolesEquals(userRoles, [UserRoles.PYA]) &&
            !rolesEquals(userRoles, [UserRoles.PYA_ADMIN]) && (
              <Card
                header="Certificates on Wallet (Till Date)"
                className="dashboard-card"
              >
                <div className="wallet-certificates-card">
                  <ResponsiveContainer className="wallet-certificates-chart__container">
                    <RadialBarChart
                      width={730}
                      height={250}
                      innerRadius="60%"
                      outerRadius="100%"
                      data={[
                        {
                          name: 'downloads',
                          value: downloadsPercentage,
                          fill: 'var(--ncap-300)',
                        },
                      ]}
                      startAngle={90}
                      endAngle={-270}
                      barSize={100}
                    >
                      <RadialBar
                        label={{
                          fill: '#666',
                          position: 'center',
                          valueAccessor: (value: { value: number }) =>
                            `${value.value}%`,
                        }}
                        background
                        dataKey="value"
                        min={15}
                        angleAxisId={0}
                      />
                      <PolarAngleAxis
                        type="number"
                        domain={[0, 100]}
                        angleAxisId={0}
                        tick={false}
                      />
                    </RadialBarChart>
                  </ResponsiveContainer>
                  <div className="count-box">
                    <div className="count-box__number">
                      {(walletDownloadsTotal as unknown as GetTotalsResponse)
                        ?.TOTALS
                        ? ((
                            walletDownloadsTotal as unknown as GetTotalsResponse
                          )?.TOTALS[0] ?? 0)
                        : ((
                            walletDownloadsTotal as unknown as {
                              data: TotalDownloads;
                            }
                          )?.data.total ?? 0)}
                    </div>
                    <div className="count-box__label">Certificates</div>
                  </div>
                  <div className="percentage-box">
                    {`${downloadsPercentage}% of total certificates are on wallet`}
                  </div>
                </div>
              </Card>
            )}
        </div>
      </div>
    </Page>
  );
};

export default Dashboard;
