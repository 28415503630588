import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Button, IconButton, Popover } from '@mui/material';

import Page from 'src/layouts/BaseLayout/components/Page/Page';

import Icon from 'src/shared/components/Icon/Icon';
import Card from 'src/shared/components/Card/Card';
import Input from 'src/shared/components/Input/Input';
import Dropdown from 'src/shared/components/Dropdown/Dropdown';
import { IconType } from 'src/shared/components/Icon/IconType';

import { store } from 'src/features/store/store';
import { MODALS } from 'src/features/modal/models';
import { useCheckAuthorization } from 'src/features/hooks';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import {
  ApplicationDocument,
  ApplicationDocumentType,
  CoCRequestType,
  GUESTApplicationStatus,
  documentsTypes,
} from 'src/features/cocRequests/models';
import {
  useGetGUESTApplicationByIdQuery,
  useGetGUESTApplicationDocumentsQuery,
} from 'src/features/cocRequests/api/cocRequestsApi';
import { setRequestId } from 'src/features/cocRequests/slices/cocRequestsSlice';

import SeaServiceDays from './components/SeaServiceDays/SeaServiceDays';
import DocumentPreview from './components/DocumentPreview/DocumentPreview';

import './CoCRequestDetailed.scss';

type RequestDocuments = {
  applicationRequirements: ApplicationDocument[];
  coursesRequired: ApplicationDocument[];
  other: ApplicationDocument[];
};

const STCW_CERTIFICATES = ['STCW-1', 'STCW-2', 'STCW-3', 'STCW-4', 'STCW-5'];

const CoCRequestDetailed = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { data: cocRequest } = useGetGUESTApplicationByIdQuery(
    { applicationUid: id as string },
    {
      skip: !id,
    }
  );
  const { data: documents } = useGetGUESTApplicationDocumentsQuery(
    { applicationUid: id as string },
    {
      skip: !id,
    }
  );

  const [requestDocuments, setRequestDocuments] = useState<RequestDocuments>({
    applicationRequirements: [],
    coursesRequired: [],
    other: [],
  });

  useEffect(() => {
    if (cocRequest?.data) {
      const type =
        CoCRequestType[
          cocRequest?.data.type as unknown as keyof typeof CoCRequestType
        ];

      const applicationRequirements = documents?.data.filter((doc) => {
        return [
          ...documentsTypes[type].coursesCompleted,
          ...STCW_CERTIFICATES,
        ].includes(
          ApplicationDocumentType[
            doc.documentType as keyof typeof ApplicationDocumentType
          ]
        );
      });
      const coursesRequired = documents?.data.filter((doc) =>
        documentsTypes[type].requiredForApplication.includes(
          ApplicationDocumentType[
            doc.documentType as keyof typeof ApplicationDocumentType
          ]
        )
      );
      const other = documents?.data.filter(
        (doc) =>
          ApplicationDocumentType[
            doc.documentType as keyof typeof ApplicationDocumentType
          ] === ApplicationDocumentType.CV ||
          ApplicationDocumentType[
            doc.documentType as keyof typeof ApplicationDocumentType
          ] === ApplicationDocumentType.PASSPORT
      );
      setRequestDocuments({
        applicationRequirements: applicationRequirements ?? [],
        coursesRequired: coursesRequired ?? [],
        other: other ?? [],
      });
    }
  }, [cocRequest?.data, documents?.data]);

  useCheckAuthorization();

  const dropdownItems = [
    {
      text: 'See communication trail',
      icon: <Icon icon={IconType.Send} />,
      onClick: () => {
        store.dispatch(changeModal(MODALS.COMMUNICATION_TRAIL));
      },
    },
  ];

  const dropdownIsOpen = Boolean(anchorEl);

  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  const onApproveClick = () => {
    if (id) {
      store.dispatch(setRequestId(id));
      store.dispatch(changeModal(MODALS.APPROVE_COC));
    }
  };

  const onReturnToSeafarerClick = () => {
    if (id) {
      store.dispatch(setRequestId(id));
      store.dispatch(changeModal(MODALS.RETURN_COC_TO_SEAFARER));
    }
  };

  const onRejectClick = () => {
    if (id) {
      store.dispatch(setRequestId(id));
      store.dispatch(changeModal(MODALS.REJECT_COC));
    }
  };

  return (
    <>
      <Page>
        <div className="coc-request-detailed">
          <div className="coc-request-detailed__header">
            <div className="coc-request-detailed__header_left">
              <IconButton onClick={() => navigate(-1)}>
                <Icon icon={IconType.Chevron} />
              </IconButton>
              <h1 className="request-header__title">
                {`GUEST ${
                  cocRequest?.data.type
                    ? CoCRequestType[cocRequest?.data.type]
                    : ''
                } CoC`}
              </h1>
            </div>
            <div className="coc-request-detailed__header_right">
              {cocRequest?.data.status ===
                GUESTApplicationStatus.DOCUMENTS_UPLOADED && (
                <Button
                  variant="contained"
                  startIcon={
                    <Icon
                      icon={IconType.CheckCircle}
                      className="coc-request-detailed__icon_approve"
                    />
                  }
                  onClick={onApproveClick}
                  disabled={
                    cocRequest?.data.status !==
                    GUESTApplicationStatus.DOCUMENTS_UPLOADED
                  }
                >
                  Approve
                </Button>
              )}
              {cocRequest?.data.status ===
                GUESTApplicationStatus.DOCUMENTS_UPLOADED && (
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={
                    <Icon
                      icon={IconType.ArrowBack}
                      className="coc-request-detailed__icon_return"
                    />
                  }
                  onClick={onReturnToSeafarerClick}
                >
                  Return to Seafarer
                </Button>
              )}
              {cocRequest?.data.status ===
                GUESTApplicationStatus.DOCUMENTS_UPLOADED && (
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={
                    <Icon
                      icon={IconType.Cross}
                      className="coc-request-detailed__icon_error"
                    />
                  }
                  onClick={onRejectClick}
                  disabled={
                    cocRequest?.data.status !==
                    GUESTApplicationStatus.DOCUMENTS_UPLOADED
                  }
                >
                  Reject CoC
                </Button>
              )}
              <IconButton
                className="coc-request__button_actions"
                onClick={handleActionsClick}
              >
                <Icon
                  icon={IconType.Dots}
                  className="coc-request-detailed__icon_actions"
                />
              </IconButton>
              <Popover
                open={dropdownIsOpen}
                anchorEl={anchorEl}
                onClose={handleActionsClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Dropdown
                  elements={dropdownItems}
                  element={cocRequest?.data}
                  callbackOnClick={handleActionsClose}
                />
              </Popover>
            </div>
          </div>
          <Formik
            initialValues={cocRequest?.data ?? {}}
            enableReinitialize
            onSubmit={(values) => console.log(values)}
          >
            <Form className="coc-request-form">
              <Card className="coc-request-detailed__card">
                <h2 className="card__header">Personal information</h2>
                <div>
                  <Input name="firstName" label="First name" disabled />
                  <Input name="middleName" label="Middle name" disabled />
                  <Input name="lastName" label="Last name" disabled />
                  <Input
                    name="email"
                    label="Email Address"
                    type="email"
                    disabled
                  />
                </div>

                <div className="coc-request-detailed__section">
                  <h2 className="group__header">
                    Sea Service Evidence supplied by:
                  </h2>
                  Professional Yachting Association SRB
                  <SeaServiceDays />
                </div>
                <div className="coc-request-detailed__section">
                  <h2 className="coc-request-form__group-header">
                    Courses completed
                  </h2>
                  {requestDocuments.coursesRequired.map((doc, index) => (
                    <DocumentPreview
                      name={
                        ApplicationDocumentType[
                          doc.documentType as unknown as keyof typeof ApplicationDocumentType
                        ]
                      }
                      key={index}
                      applicationUid={id ?? ''}
                      documentUid={doc.documentUid}
                    />
                  ))}
                </div>

                <div className="coc-request-detailed__section">
                  <h2 className="coc-request-form__group-header">
                    Required for application
                  </h2>
                  {requestDocuments.applicationRequirements.map(
                    (doc, index) => (
                      <DocumentPreview
                        name={
                          ApplicationDocumentType[
                            doc.documentType as unknown as keyof typeof ApplicationDocumentType
                          ]
                        }
                        key={index}
                        applicationUid={id ?? ''}
                        documentUid={doc.documentUid}
                        image={
                          doc.documentType ===
                          ApplicationDocumentType.PASSPORT_PHOTO
                        }
                      />
                    )
                  )}
                </div>

                <div className="coc-request-detailed__other-documents coc-request-detailed__section">
                  <h2 className="coc-request-form__group-header">
                    Other documents
                  </h2>
                  {requestDocuments.other.map((doc, index) =>
                    (doc.documentType as string) === '' ? null : (
                      <DocumentPreview
                        name={
                          ApplicationDocumentType[
                            doc.documentType as unknown as keyof typeof ApplicationDocumentType
                          ]
                        }
                        key={index}
                        applicationUid={id ?? ''}
                        documentUid={doc.documentUid}
                      />
                    )
                  )}
                  {requestDocuments.other.length !== 0 && (
                    <DocumentPreview
                      name={ApplicationDocumentType.PASSPORT_PHOTO}
                      key={ApplicationDocumentType.PASSPORT_PHOTO}
                      applicationUid={id ?? ''}
                      image
                    />
                  )}
                  {/* <Button
                    variant="outlined"
                    color="primary"
                    endIcon={
                      <Icon
                        icon={IconType.Download}
                        className="coc-request-detailed__download-icon"
                      />
                    }
                    className="coc-request-detailed__download-button"
                  >
                    Download all
                  </Button> */}
                </div>
              </Card>
            </Form>
          </Formik>
        </div>
      </Page>
    </>
  );
};

export default CoCRequestDetailed;
