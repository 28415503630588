import { ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AuthLayout from 'src/layouts/AuthLayout/AuthLayout';
import BaseLayout from 'src/layouts/BaseLayout/BaseLayout';
import DocumentsUploadLayout from 'src/layouts/DocumentsUploadLayout/DocumentsUploadLayout';
import { FORM_TYPES } from 'src/shared/enums';
import SignIn from 'src/pages/SignIn/SignIn';
import Settings from 'src/pages/Settings/Settings';
import Campaigns from 'src/pages/Campaigns/Campaigns';
import Dashboard from 'src/pages/Dashboard/Dashboard';
import CoCRequests from 'src/pages/CoCRequests/CoCRequests';
import CoCPayments from 'src/pages/CoCPayments/CoCPayments';
import Credentials from 'src/pages/Credentials/Credentials';
import SSTRequests from 'src/pages/SSTRequests/SSTRequests';
import MobileUsers from 'src/pages/MobileUsers/MobileUsers';
import TemplateForm from 'src/pages/TemplateForm/TemplateForm';
import IssuerForm from 'src/pages/IssuerForm/IssuerForm';
import Notifications from 'src/pages/Notifications/Notifications';
import CreateTemplate from 'src/pages/CreateTemplate/CreateTemplate';
import SettingsContextWrapper from 'src/pages/Settings/SettingsContext';
import CampaignCreating from 'src/pages/CampaignCreating/CampaignCreating';
import CampaignDetailed from 'src/pages/CampaignDetailed/CampaignDetailed';
import CoCRequestDetailed from 'src/pages/CoCRequestDetailed/CoCRequestDetailed';
import SSTRequestDetailed from 'src/pages/SSTRequestDetailed/SSTRequestDetailed';
import ResponsiblePersons from 'src/pages/ResponsiblePersons/ResponsiblePersons';
import UploadGUESTDocuments from 'src/pages/UploadGUESTDocuments/UploadGUESTDocuments';
import NewCredentialCreating from 'src/pages/NewCredentialCreating/NewCredentialCreating';
import ConfirmPrimaryEmailMobile from 'src/pages/ConfirmPrimaryEmailMobile/ConfirmPrimaryEmailMobile';
import ConfirmSecondaryEmailMobile from 'src/pages/ConfirmSecondaryEmailMobile/ConfirmSecondaryEmailMobile';

import ResetPassword from 'src/features/auth/components/ResetPassword/ResetPassword';
import ForgotPassword from 'src/features/auth/components/ForgotPassword/ForgotPassword';

import { theme } from 'src/styles/theme';

import { routes } from './routes';

const Router = () => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path={routes.root.index} element={<AuthLayout />}>
          <Route index element={<SignIn />} />
          <Route
            path={routes.auth.forgotPassword}
            element={<ForgotPassword />}
          />
          <Route path={routes.auth.resetPassword} element={<ResetPassword />} />
          <Route
            path={routes.auth.resetPasswordConfirm}
            element={<ResetPassword />}
          />
        </Route>
        <Route path={routes.root.index} element={<BaseLayout />}>
          <Route path={routes.root.dashboard} element={<Dashboard />} />
          <Route path={routes.root.requests}>
            <Route index element={<SSTRequests />} />
            <Route path={routes.id} element={<SSTRequestDetailed />} />
          </Route>
          <Route path={routes.root.campaigns}>
            <Route index element={<Campaigns />} />
            <Route
              path={routes.campaigns.createCampaign}
              element={<CampaignCreating />}
            />
            <Route path={routes.id} element={<CampaignDetailed />} />
          </Route>
          <Route path={routes.root.cocRequests}>
            <Route index element={<CoCRequests />} />
            <Route path={routes.id} element={<CoCRequestDetailed />} />
          </Route>
          <Route path={routes.root.cocPayments}>
            <Route index element={<CoCPayments />} />
            <Route index element={<CoCRequests />} />
          </Route>
          <Route path={routes.root.credentials}>
            <Route index element={<Credentials />} />
            <Route
              path={routes.credentials.createCredential}
              element={<NewCredentialCreating />}
            />
          </Route>
          <Route
            path={routes.root.responsiblePersons}
            element={<ResponsiblePersons />}
          />
          <Route path={routes.root.mobileUsers} element={<MobileUsers />} />
          <Route
            path={routes.root.settings}
            element={<SettingsContextWrapper />}
          >
            <Route index element={<Settings tabNumber={0} />} />
            <Route
              path={routes.settings.templates}
              element={<Settings tabNumber={1} />}
            />
            <Route
              path={routes.settings.issuers}
              element={<Settings tabNumber={2} />}
            />
            <Route
              path={routes.settings.admins}
              element={<Settings tabNumber={3} />}
            />
            <Route
              path={`${routes.settings.admins}/${routes.settings.companies}/${routes.id}`}
              element={<Settings tabNumber={3} />}
            />
            <Route
              path={`${routes.settings.admins}/${routes.settings.companies}/${routes.id}/${routes.postfix}`}
              element={<Settings tabNumber={3} />}
            />
            <Route
              path={routes.settings.templateDesigner}
              element={<Settings tabNumber={4} />}
            />
            <Route
              path={routes.settings.createTemplate}
              element={<CreateTemplate />}
            />
            <Route
              path={`${routes.settings.viewTemplate}/${routes.id}`}
              element={<TemplateForm type={FORM_TYPES.VIEW} />}
            />
            <Route
              path={`${routes.settings.editTemplate}/${routes.id}`}
              element={<TemplateForm type={FORM_TYPES.EDIT} />}
            />
            <Route
              path={routes.settings.createIssuer}
              element={<IssuerForm type={FORM_TYPES.CREATE} />}
            />
            <Route
              path={`${routes.settings.viewIssuer}/${routes.id}`}
              element={<IssuerForm type={FORM_TYPES.VIEW} />}
            />
            <Route
              path={`${routes.settings.editIssuer}/${routes.id}`}
              element={<IssuerForm type={FORM_TYPES.EDIT} />}
            />
          </Route>
          <Route path={routes.root.notifications} element={<Notifications />} />
        </Route>
        <Route
          path={`${routes.root.guest}`}
          element={<DocumentsUploadLayout />}
        >
          <Route
            index
            path={`${routes.id}/${routes.guest.uploadDocuments}`}
            element={<UploadGUESTDocuments />}
          />
        </Route>
        <Route
          path={`${routes.mobile.index}/${routes.mobile.confirmPrimaryEmail}`}
          element={<ConfirmPrimaryEmailMobile />}
        />
        <Route
          path={`${routes.mobile.index}/${routes.mobile.confirmSecondaryEmail}`}
          element={<ConfirmSecondaryEmailMobile />}
        />
      </Routes>
    </ThemeProvider>
  </BrowserRouter>
);

export default Router;
