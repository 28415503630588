import { useEffect, useMemo, useState } from 'react';

export const useLoadFonts = () => {
  const [robotoFont, setRobotoFont] = useState<ArrayBuffer>();
  const [arialFont, setArialFont] = useState<ArrayBuffer>();
  const [helveticaFont, setHelveticaFont] = useState<ArrayBuffer>();
  const [tnrFont, setTNRFont] = useState<ArrayBuffer>();

  useEffect(() => {
    fetch(`/fonts/Roboto.ttf`).then(async (res) => {
      setRobotoFont(await res.arrayBuffer());
    });
    fetch(`/fonts/Arial.ttf`).then(async (res) =>
      setArialFont(await res.arrayBuffer())
    );
    fetch(`/fonts/Helvetica.ttf`).then(async (res) =>
      setHelveticaFont(await res.arrayBuffer())
    );
    fetch(`/fonts/timesnewroman.ttf`).then(async (res) =>
      setTNRFont(await res.arrayBuffer())
    );
  }, []);

  const fonts = useMemo(() => {
    if (!robotoFont || !arialFont || !helveticaFont || !tnrFont) {
      return;
    }
    return {
      Roboto: {
        data: robotoFont,
        fallback: true,
      },
      Arial: {
        data: arialFont,
      },
      Helvetica: {
        data: helveticaFont,
      },
      ['Times New Roman']: {
        data: tnrFont,
      },
    };
  }, [arialFont, helveticaFont, robotoFont, tnrFont]);
  return fonts;
};
