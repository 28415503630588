import { ReactElement } from 'react';

import Spinner from 'src/shared/components/Spinner/Spinner';

import TemplatePreview from '../TemplatePreview/TemplatePreview';

import './TemplatesList.scss';

type TemplatesListProps<T> = {
  data: T[];
  actionButtons: (id: string, name?: string) => ReactElement[];
  className?: string;
  isLoading?: boolean;
  noDataElement?: ReactElement;
  subtitleCallback?: (template: T) => string | undefined;
};

const TemplatesList = <
  T extends { uid: string; name: string; image?: string },
>({
  data,
  actionButtons,
  className,
  isLoading,
  noDataElement,
  subtitleCallback,
}: TemplatesListProps<T>) => {
  const renderContent = () => {
    if (data.length !== 0) {
      return (
        <div className={`templates-list ${className ?? ''}`}>
          {data.map((template, key) => (
            <TemplatePreview
              id={template.uid}
              key={key}
              name={template.name}
              actionButtons={actionButtons}
              image={template.image}
              subtitle={
                subtitleCallback ? subtitleCallback(template) : undefined
              }
            />
          ))}
        </div>
      );
    } else if (isLoading) {
      return (
        <div className="templates-list_spinner">
          <Spinner />
        </div>
      );
    }
    return noDataElement ?? null;
  };
  return renderContent();
};

export default TemplatesList;
