export enum MODALS {
  APPROVE_SST,
  VALIDATE_SST,

  APPROVE_COC,
  REJECT_COC,
  RETURN_COC_TO_SEAFARER,

  RETURN_TO_RP,
  RETURN_TO_SEAFARER,
  RETURN_TO_UNVALIDATED,

  ADD_RP_FORM,
  EDIT_RP_FORM,
  VERIFY_RP_FORM,

  ADD_RECIPIENT_FORM,
  EDIT_RECIPIENT_FORM,

  RESET_PASSWORD,
  ACTIVATE_ACCOUNT,

  ADD_COMPANY,
  ADD_COMPANY_USER,
  EDIT_COMPANY_USER,

  CAMPAIGN_PREVIEW,
  CAMPAIGN_SUCCESS,

  IMPORT_SPREADSHEET,
  CREDENTIALS_CREATION,
  COMMUNICATION_TRAIL,
  LOGOUT,
}
