import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';

import { paths } from 'src/app/routes';

export type SettingsPrevPage = 'Company' | 'Templates' | 'Issuers';

type SettingsContextType = {
  prevPage: SettingsPrevPage | null;
  setPrevPage: Dispatch<SetStateAction<SettingsPrevPage | null>>;
  getPath: (companyUid: string | undefined, postfix?: string) => string;
};

export const SettingsContext = createContext<SettingsContextType | null>(null);

const SettingsContextWrapper = () => {
  const [prevPage, setPrevPage] = useState<SettingsPrevPage | null>(null);

  const getPath = useCallback(
    (companyUid: string | undefined, postfix?: string) => {
      switch (prevPage) {
        case 'Company':
          return companyUid
            ? paths.companyById(companyUid, postfix)
            : paths.settings;
        case 'Templates':
          return paths.settingsTemplates;
        case 'Issuers':
          return paths.settingsIssuers;
        default:
          return paths.settings;
      }
    },
    [prevPage]
  );

  return (
    <SettingsContext.Provider value={{ prevPage, setPrevPage, getPath }}>
      <Outlet />
    </SettingsContext.Provider>
  );
};

export default SettingsContextWrapper;
