import {
  Field,
  ErrorMessage,
  FieldAttributes,
  useFormikContext,
  useField,
  getIn,
} from 'formik';
import { useMemo } from 'react';
import ReactSelect from 'react-select';

import { FormField } from 'src/shared/models';
import TextError from '../TextError/TextError';

import './Select.scss';

export const OTHER = 'Other';

type SelectOptionProps = {
  value: string;
  label: string;
};

type SelectProps = FieldAttributes<unknown> & {
  label: string;
  name: string;
  placeholder?: string;
  options: SelectOptionProps[];
  otherOption?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  required?: boolean;
};

const Select = ({
  label,
  name,
  placeholder,
  options,
  otherOption,
  multiple,
  disabled,
  required,
  ...rest
}: SelectProps) => {
  const { setFieldValue, errors } = useFormikContext<FormField>();
  const [field] = useField(name);

  const optionsArray = otherOption
    ? [
        ...options,
        {
          value: OTHER,
          label: OTHER,
        },
      ]
    : options;

  const otherName = otherOption ? `${name}Other` : '';

  const showTextField = useMemo(
    () => otherOption && field.value === OTHER,
    [field.value, otherOption]
  );

  const getFromOptions = () =>
    optionsArray?.filter((o) => {
      const isArrayValue = Array.isArray(field.value);

      if (isArrayValue) {
        const values = field.value as Array<string>;
        return values.includes(o.value);
      } else {
        return field.value === o.value;
      }
    });

  const setOtherOption = () => {
    const currentValue = field.value;
    setFieldValue(name, OTHER);
    setFieldValue(otherName, currentValue);
  };

  const value =
    otherOption && !getFromOptions().length
      ? setOtherOption()
      : getFromOptions();

  const onChange = (value: SelectOptionProps[] | SelectOptionProps) => {
    const isArray = Array.isArray(value);
    if (isArray) {
      const values = value.map((o) => o.value);
      setFieldValue(name, values);
    } else {
      setFieldValue(name, value.value);
    }
  };

  return (
    <div className="form-control select">
      <label className="input__label" htmlFor={name}>
        {label}
        {required ? <span className="input__label_required">*</span> : null}
      </label>
      <Field
        id={name}
        name={name}
        as={ReactSelect}
        value={value}
        isMulti={multiple}
        isDisabled={disabled}
        placeholder={placeholder}
        options={optionsArray}
        onChange={onChange}
        styles={{
          menuPortal: (base: object) => ({ ...base, zIndex: 10 }),
          menu: (base: object) => ({ ...base, zIndex: 10 }),
        }}
        theme={(theme: { colors: object }) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'var(--ncap-300)',
          },
        })}
        {...rest}
      />
      {showTextField ? (
        <>
          <Field
            className="form-input"
            type="text"
            id={otherName}
            name={otherName}
            disabled={disabled}
            {...rest}
          />
          <TextError>{getIn(errors, otherName)}</TextError>
        </>
      ) : null}
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default Select;
