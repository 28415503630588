import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { MRT_ColumnDef } from 'material-react-table';
import { generate } from '@pdfme/generator';
import { GenerateProps } from '@pdfme/common';

import { FormField } from 'src/shared/models';
import Icon from 'src/shared/components/Icon/Icon';
import Table from 'src/shared/components/Table/Table';
import Spinner from 'src/shared/components/Spinner/Spinner';
import { IconType } from 'src/shared/components/Icon/IconType';
import { DATE_FORMAT, DATE_WITHOUT_TIMEZONE } from 'src/shared/utils';
import FileUploader from 'src/shared/components/FileUploader/FileUploader';
import { getPlugins } from 'src/shared/components/TemplateDesigner/utils';

import { MODALS } from 'src/features/modal/models';
import { State, store } from 'src/features/store/store';
import { LabelTypes } from 'src/features/templates/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { TEMPLATES } from 'src/features/templates/slices/templatesSlice';
import { CredentialRecipientDetails } from 'src/features/credentials/models';
import { useLazyGetDocumentTemplateQuery } from 'src/features/documents/api/documentsApi';
import {
  CREDENTIALS,
  deleteCredentialDetailByIndex,
  getCredentialDetailByIndex,
} from 'src/features/credentials/slices/credentialsSlice';

import './AddRecipientDetails.scss';

type AddRecipientDetailsProps = {
  templateWithDesign: boolean;
  onFileChange: (param: boolean) => void;
};

const DATE_FIELD_NAMES = ['issuedOn', 'birthdate', 'expires'];

const AddRecipientDetails = ({
  templateWithDesign,
  onFileChange,
}: AddRecipientDetailsProps): JSX.Element => {
  const [includeDocument, setIncludeDocument] = useState<boolean>(false);
  const { setFieldValue } = useFormikContext<FormField>();
  const credentialDetails = useSelector(
    (s: State) => s[CREDENTIALS].credentialRecipientsDetails
  );
  const template = useSelector((s: State) => s[TEMPLATES].template);

  const [getDocumentTemplate, getDocumentTemplateResult] =
    useLazyGetDocumentTemplateQuery();

  const onPreview = useCallback(
    async (row: CredentialRecipientDetails) => {
      let templateData;
      if (
        template?.designTemplateUid &&
        getDocumentTemplateResult.isUninitialized
      ) {
        templateData = (await getDocumentTemplate(template.designTemplateUid))
          .data;
      } else {
        templateData = getDocumentTemplateResult.data;
      }
      const inputs = [
        Object.fromEntries(
          Object.keys(row).map((key) => {
            const value =
              (template?.renderParams[key] &&
                template?.renderParams[key].fieldType === LabelTypes.DATE) ||
              (row[key] !== undefined && DATE_FIELD_NAMES.includes(key))
                ? dayjs(
                    row[key] as string | number | Date | Dayjs,
                    DATE_FORMAT
                  ).format('DD-MM-YYYY')
                : row[key]?.toString();
            return [key, value ?? ''];
          })
        ),
      ];

      if (templateData && row) {
        const pdf = await generate({
          template: templateData as GenerateProps['template'],
          inputs,
          plugins: getPlugins(),
        });

        const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
        window.open(URL.createObjectURL(blob));
      }
    },
    [
      getDocumentTemplate,
      getDocumentTemplateResult.data,
      getDocumentTemplateResult.isUninitialized,
      template,
    ]
  );

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'firstName',
        Cell: ({ row }) =>
          `${row.getValue('firstName')}  ${
            (row.original as CredentialRecipientDetails).lastName
          }`,
      },
      {
        header: 'Email',
        accessorKey: 'email',
        size: 150,
      },
      {
        header: 'Internal Identifier',
        accessorKey: 'secondaryIdentifier',
        size: 150,
      },
      {
        header: 'Issuance Date',
        accessorKey: 'issuedOn',
        size: 110,
        Cell: ({ cell }) => {
          return dayjs(cell.getValue<string>(), DATE_FORMAT).format(
            DATE_WITHOUT_TIMEZONE
          );
        },
      },
      {
        header: 'Expiry Date',
        accessorKey: 'expires',
        size: 110,
        Cell: ({ cell }) =>
          cell.getValue<string>()
            ? dayjs(cell.getValue<string>(), DATE_FORMAT).format(
                DATE_WITHOUT_TIMEZONE
              )
            : '',
      },
      {
        header: '',
        id: 'preview',
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => {
          return getDocumentTemplateResult.isFetching ? (
            <Spinner />
          ) : (
            <Tooltip title="Preview">
              <IconButton
                className="button_row-actions"
                onClick={() =>
                  onPreview(row.original as CredentialRecipientDetails)
                }
              >
                <Icon icon={IconType.Document} />
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        header: '',
        id: 'edit',
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => {
          return (
            <IconButton
              className="button_row-actions"
              onClick={() => {
                store.dispatch(changeModal(MODALS.EDIT_RECIPIENT_FORM));
                store.dispatch(getCredentialDetailByIndex(row.index));
              }}
            >
              <Icon icon={IconType.Pencil} />
            </IconButton>
          );
        },
      },
      {
        header: '',
        id: 'delete',
        size: 50,
        Cell: ({ row }) => {
          return (
            <IconButton
              className="button_row-actions"
              onClick={() =>
                store.dispatch(deleteCredentialDetailByIndex(row.index))
              }
            >
              <Icon icon={IconType.CrossCircle} />
            </IconButton>
          );
        },
      },
    ],
    [getDocumentTemplateResult.isFetching, onPreview]
  );

  return (
    <>
      <div className="add-recipient-form__actions">
        <Button
          variant="contained"
          startIcon={<Icon icon={IconType.Plus} />}
          onClick={() => store.dispatch(changeModal(MODALS.ADD_RECIPIENT_FORM))}
          disabled={!templateWithDesign && credentialDetails.length >= 1}
        >
          Add Recipient
        </Button>
        {templateWithDesign ? (
          <Button
            variant="outlined"
            onClick={() =>
              store.dispatch(changeModal(MODALS.IMPORT_SPREADSHEET))
            }
          >
            Import Spreadsheet
          </Button>
        ) : null}
      </div>
      <Table<CredentialRecipientDetails>
        columns={columns}
        data={credentialDetails}
        enableSorting
        enableRowNumbers
      />
      {templateWithDesign ? null : (
        <p>
          Credentials with templates without design cannot be created in batch
        </p>
      )}
      <div className="add-recipient-form__checkboxes-container">
        <FormControlLabel
          control={
            <Checkbox
              value={includeDocument}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setIncludeDocument(event.target.checked)
              }
              disabled={credentialDetails.length > 1}
            />
          }
          label="Include document"
        />
        {includeDocument && !(credentialDetails.length > 1) ? (
          <FileUploader fieldName="file" onChange={onFileChange} />
        ) : null}

        <FormControlLabel
          control={
            <Checkbox
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('sendToEmail', event.target.checked)
              }
            />
          }
          label="Send credentials via email to all recipients"
        />
      </div>
    </>
  );
};

export default AddRecipientDetails;
