import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Company, CompanyUser } from '../models';

export const COMPANIES = 'COMPANIES';

interface CompaniesState {
  company: Company | null;
  user: CompanyUser | null;
}

const initialState: CompaniesState = {
  company: null,
  user: null,
};

const companiesSlice = createSlice({
  name: COMPANIES,
  initialState,
  reducers: {
    setCompany: (
      state: CompaniesState,
      action: PayloadAction<Company | null>
    ) => {
      state.company = action.payload;
    },
    setCompanyUser: (
      state: CompaniesState,
      action: PayloadAction<CompanyUser | null>
    ) => {
      state.user = action.payload;
    },
  },
});

export const { setCompany, setCompanyUser } = companiesSlice.actions;

export default companiesSlice.reducer;
