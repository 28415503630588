import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Issuer } from '../models';

export const ISSUERS = 'ISSUERS';

interface IssuersState {
  issuer: Issuer | null;
}

const initialState: IssuersState = {
  issuer: {
    uid: '',
    name: '',
    image: '',
    email: '',
    url: '',
    country: '',
    countryId: '',
    phone: '',
    address: '',
    userId: '',
  },
};

const issuersSlice = createSlice({
  name: ISSUERS,
  initialState,
  reducers: {
    setIssuer: (state: IssuersState, action: PayloadAction<Issuer | null>) => {
      state.issuer = action.payload;
    },
  },
});

export const { setIssuer } = issuersSlice.actions;

export default issuersSlice.reducer;
