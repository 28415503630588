import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { IconButton, Popover } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';

import { paths, routes } from 'src/app/routes';
import Icon from 'src/shared/components/Icon/Icon';
import Tabs from 'src/shared/components/Tabs/Tabs';
import Table from 'src/shared/components/Table/Table';
import Card from 'src/shared/components/Card/Card';
import { IconType } from 'src/shared/components/Icon/IconType';
import Page from 'src/layouts/BaseLayout/components/Page/Page';
import SearchBar from 'src/shared/components/SearchBar/SearchBar';

import { State, store } from 'src/features/store/store';
import PaymentStatus, {
  PaymentStatusValue,
} from 'src/features/payment/components/PaymentStatus/PaymentStatus';
import Dropdown from 'src/shared/components/Dropdown/Dropdown';
import {
  useGetTestimonialRequestsQuery,
  useLazyGetTestimonialByIdQuery,
} from 'src/features/testimonials/api/testimonialsApi';
import {
  TestimonialRequest,
  TestimonialRequestStatus,
} from 'src/features/testimonials/models';
import { MODALS } from 'src/features/modal/models';
import { DATE_WITHOUT_TIMEZONE, downloadDocument } from 'src/shared/utils';
import { useCheckAuthorization, useCheckError } from 'src/features/hooks';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { setRequestId } from 'src/features/testimonials/slices/testimonialSlice';
import { setResponsiblePerson } from 'src/features/responsiblePersons/slices/responsiblePersonsSlice';
import { AUTH } from 'src/features/auth/slices/authSlice';
import { UserRoles } from 'src/features/auth/models';

import { ReactComponent as VerifiedIcon } from './images/verified-icon.svg';

import './SSTRequests.scss';

const TAB_STATUSES = {
  [routes.statusValidated]: 1,
  [routes.statusApproved]: 2,
};

const SSTRequests = () => {
  const params = useSearchParams();
  const [search, setSearch] = useState<string>('');
  const [requests, setRequests] = useState<TestimonialRequest[]>([]);
  const [currentTab, setCurrentTab] = useState<number>(
    TAB_STATUSES[params[0].get('status') as string] ?? 0
  );
  const userRoles = useSelector((s: State) => s[AUTH].roles);

  const [getTestimonialById] = useLazyGetTestimonialByIdQuery();

  const onVerifyClick = useCallback(
    (id: string) => {
      getTestimonialById(id)
        .unwrap()
        .then((result) => {
          store.dispatch(
            setResponsiblePerson(result.data.responsiblePerson ?? null)
          );
          store.dispatch(changeModal(MODALS.VERIFY_RP_FORM));
        });
    },
    [getTestimonialById]
  );

  const dropdownItems = useMemo(() => {
    const items = [
      {
        text: 'Return to RP',
        icon: <Icon icon={IconType.Arrow} />,
        onClick: (request: TestimonialRequest) => {
          store.dispatch(changeModal(MODALS.RETURN_TO_RP));
          store.dispatch(setRequestId(request.uid));
        },
      },
      {
        text: 'Reject',
        icon: <Icon icon={IconType.ArrowBack} />,
        onClick: (request: TestimonialRequest) => {
          store.dispatch(changeModal(MODALS.RETURN_TO_SEAFARER));
          store.dispatch(setRequestId(request.uid));
        },
      },
      {
        text: 'Verify RP',
        icon: <Icon icon={IconType.Verify} />,
        onClick: (request: TestimonialRequest) => {
          onVerifyClick(request.uid);
        },
      },
    ];
    return userRoles?.includes(UserRoles.PYA)
      ? [
          {
            text: 'Validate',
            icon: <Icon icon={IconType.Pencil} />,
            onClick: (request: TestimonialRequest) => {
              store.dispatch(changeModal(MODALS.VALIDATE_SST));
              store.dispatch(setRequestId(request.uid));
            },
          },
          ...items,
        ]
      : items;
  }, [onVerifyClick, userRoles]);

  const dropdownItemsValidated = useMemo(() => {
    const items = [
      {
        text: 'Verify RP',
        icon: <Icon icon={IconType.Verify} />,
        onClick: (request: TestimonialRequest) => {
          onVerifyClick(request.uid);
        },
      },
    ];
    return userRoles?.includes(UserRoles.PYA_ADMIN)
      ? [
          {
            text: 'Approve',
            icon: <Icon icon={IconType.Pencil} />,
            onClick: (request: TestimonialRequest) => {
              store.dispatch(changeModal(MODALS.APPROVE_SST));
              store.dispatch(setRequestId(request.uid));
            },
          },
          {
            text: 'Reject',
            icon: <Icon icon={IconType.ArrowBack} />,
            onClick: (request: TestimonialRequest) => {
              store.dispatch(changeModal(MODALS.RETURN_TO_UNVALIDATED));
              store.dispatch(setRequestId(request.uid));
            },
          },
          ...items,
        ]
      : items;
  }, [onVerifyClick, userRoles]);

  const dropdownItemsApproved = useMemo(
    () => [
      {
        text: 'Download Certificate',
        icon: <Icon icon={IconType.Download} />,
        onClick: (request: TestimonialRequest) => {
          downloadDocument(request.certificateId);
        },
        disabled: (request: TestimonialRequest) => !request.certificateId,
      },
      {
        text: 'Verify RP',
        icon: <Icon icon={IconType.Verify} />,
        onClick: (request: TestimonialRequest) => {
          onVerifyClick(request.uid);
        },
      },
    ],
    [onVerifyClick]
  );

  const TABS = useMemo(
    () => [
      {
        label: 'Pending Validation',
        status: TestimonialRequestStatus.SENT_PYA,
        path: paths.requests,
        items: dropdownItems,
      },
      {
        label: 'Pending Approval',
        status: TestimonialRequestStatus.VALIDATED_PYA,
        path: paths.requestsValidated,
        items: dropdownItemsValidated,
      },
      {
        label: 'Approved',
        status: TestimonialRequestStatus.APPROVED,
        path: paths.requestsApproved,
        items: dropdownItemsApproved,
      },
    ],
    [dropdownItems, dropdownItemsApproved, dropdownItemsValidated]
  );

  const { data: testimonials, isError } = useGetTestimonialRequestsQuery(
    TABS[currentTab].status
  );

  useCheckError(isError, 'Error loading SST Requests');

  useCheckAuthorization();

  const filterRequests = useCallback(
    () =>
      testimonials
        ? testimonials?.data.filter(
            (request) =>
              request.firstName.toLowerCase().includes(search.toLowerCase()) ||
              request.middleName.toLowerCase().includes(search.toLowerCase()) ||
              request.lastName.toLowerCase().includes(search.toLowerCase()) ||
              request.email.toLowerCase().includes(search.toLowerCase()) ||
              request.responsiblePerson
                .toLowerCase()
                .includes(search.toLowerCase())
          )
        : [],
    [search, testimonials]
  );

  useEffect(() => {
    setRequests(filterRequests);
  }, [filterRequests, search]);

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'Date',
        accessorKey: 'date',
        size: 110,
        Cell: ({ cell }) =>
          dayjs(cell.getValue<string>()).format(DATE_WITHOUT_TIMEZONE),
      },
      {
        header: 'First name',
        accessorKey: 'firstName',
      },
      {
        header: 'Middle name',
        accessorKey: 'middleName',
      },
      {
        header: 'Last name',
        accessorKey: 'lastName',
      },
      {
        header: 'Email',
        accessorKey: 'email',
        size: 150,
      },
      {
        header: 'Responsible Person',
        Cell: ({ row }) => {
          const rp = row.original as TestimonialRequest;
          return (
            <div className="sst-requests__responsible-person">
              {rp.responsiblePersonVerified ? (
                <VerifiedIcon></VerifiedIcon>
              ) : null}
              {rp.responsiblePerson}
            </div>
          );
        },
      },
      {
        header: 'Payment status',
        accessorKey: 'paymentStatus',
        size: 135,
        Cell: () => <PaymentStatus value={PaymentStatusValue.PAID} />,
      },
      {
        header: '',
        id: 'actions',
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => {
          const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
            null
          );
          const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          };
          const dropdownIsOpen = Boolean(anchorEl);

          const handleClose = () => {
            setAnchorEl(null);
          };

          return (
            <>
              <IconButton onClick={handleClick} className="button_row-actions">
                <Icon icon={IconType.Dots} />
              </IconButton>
              <Popover
                open={dropdownIsOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Dropdown
                  elements={TABS[currentTab].items}
                  element={row.original as TestimonialRequest}
                />
              </Popover>
            </>
          );
        },
      },
      {
        header: '',
        id: 'form',
        size: 50,
        Cell: ({ row }) => {
          return (
            <Link
              to={paths.requestById((row.original as TestimonialRequest).uid)}
            >
              <Icon icon={IconType.ChevronSquare} />
            </Link>
          );
        },
      },
    ],
    [TABS, currentTab]
  );

  const handleTabClick = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Page>
      <div className="sst-requests">
        <h1 className="sst-requests__header">
          Sea Service Testimonial requests
        </h1>
        <SearchBar onChange={(e) => setSearch(e.target.value)} />

        <Card className="sst-requests__table-container">
          <Tabs value={currentTab} values={TABS} onChange={handleTabClick} />
          <Table<TestimonialRequest>
            columns={columns}
            data={requests}
            enableSorting
            initialState={{
              sorting: [
                {
                  id: 'date',
                  desc: true,
                },
              ],
            }}
          />
        </Card>
      </div>
    </Page>
  );
};

export default SSTRequests;
