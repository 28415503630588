import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { addAuthorizationHeaderWithBearer } from 'src/shared/api/utils';

import {
  Campaign,
  CreateCampaignRequest,
  GetCampaignsRequest,
  GetCampaignsResponse,
  GetCampaignUsersRequest,
  GetCampaignUsersResponse,
} from '../models';
import { DefaultResponse } from 'src/shared/models';

export const CAMPAIGNS_API = 'CAMPAIGNS_API';

const preparePagination = (request: GetCampaignsRequest) => {
  const pagination = request.page !== undefined &&
    request.size !== undefined && {
      page: request.page,
      size: request.size,
    };

  return pagination;
};

const campaignsApi = createApi({
  reducerPath: CAMPAIGNS_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_API_URL,
      prepareHeaders: (headers) => addAuthorizationHeaderWithBearer(headers),
    }),
    {
      maxRetries: 0,
    }
  ),
  tagTypes: ['CAMPAIGNS'],
  endpoints: (builder) => ({
    getCampaigns: builder.query<GetCampaignsResponse, GetCampaignsRequest>({
      query: (request) => ({
        method: 'GET',
        url: `campaign`,
        params: {
          ...preparePagination(request),
        },
      }),
      providesTags: ['CAMPAIGNS'],
    }),
    createCampaign: builder.mutation<DefaultResponse, CreateCampaignRequest>({
      query: (request) => ({
        method: 'POST',
        url: `campaign`,
        body: request,
      }),
      invalidatesTags: ['CAMPAIGNS'],
    }),
    getCampaign: builder.query<Campaign, string>({
      query: (campaignUid) => ({
        method: 'GET',
        url: `campaign/${campaignUid}`,
      }),
      providesTags: ['CAMPAIGNS'],
    }),
    getCampaignUsers: builder.query<
      GetCampaignUsersResponse,
      GetCampaignUsersRequest
    >({
      query: (request) => ({
        method: 'GET',
        url: `campaign/${request.campaignUid}/users`,
        params: {
          ...preparePagination(request),
        },
      }),
      providesTags: ['CAMPAIGNS'],
    }),
    sendCampaignNotification: builder.mutation<DefaultResponse, string>({
      query: (campaignUid) => ({
        method: 'POST',
        url: `campaign/${campaignUid}/send`,
      }),
      invalidatesTags: ['CAMPAIGNS'],
    }),
  }),
});

export const {
  useGetCampaignsQuery,
  useCreateCampaignMutation,
  useGetCampaignQuery,
  useGetCampaignUsersQuery,
  useSendCampaignNotificationMutation,
} = campaignsApi;

export const campaignsMiddleware = campaignsApi.middleware;

export default campaignsApi.reducer;
