import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';

import Input from 'src/shared/components/Input/Input';

import { paths } from 'src/app/routes';
import { ResetPasswordRequest } from 'src/features/auth/models';
import { useResetPasswordMutation } from 'src/features/auth/api/authApi';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';

import './ForgotPassword.scss';

const initialForgotPasswordValues = {
  email: '',
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .required('This field is required'),
});

const ForgotPassword = (): JSX.Element => {
  const [successPage, setSuccessPage] = useState<boolean>();
  const [resetPassword, resetPasswordResult] = useResetPasswordMutation();
  const onSubmit = (value: ResetPasswordRequest) => resetPassword(value);

  useEffect(() => {
    if (resetPasswordResult.isError) {
      toast.error('Sending email error', { containerId: CONTAINER_ID_ACTION });
    }
    if (resetPasswordResult.isSuccess) {
      setSuccessPage(true);
    }
  }, [resetPasswordResult]);

  return successPage ? (
    <>
      <h1 className="auth-header">Email sent!</h1>
      <h2 className="auth-subheader">
        We have sent an email to the address provided. Click the link in the
        email to reset your password.
      </h2>
      <div className="forgot-password__button-container">
        <Button component={Link} to={paths.signIn}>
          Back to login
        </Button>

        <Button
          type="submit"
          variant="contained"
          onClick={() => setSuccessPage(false)}
        >
          Send email again
        </Button>
      </div>
    </>
  ) : (
    <>
      <h1 className="auth-header">Forgot password?</h1>
      <h2 className="auth-subheader">
        Please enter the email address you&apos;d like your password reset
        information sent to.
      </h2>
      <Formik
        initialValues={initialForgotPasswordValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form className="forgot-password">
          <Input
            name="email"
            placeholder="email@gmail.com"
            label="Email"
            type="email"
          />
          <div className="forgot-password__button-container">
            <Button component={Link} to={paths.signIn}>
              Back to login
            </Button>
            {resetPasswordResult.isLoading ? (
              <div className="forgot-password__spinner-container">
                <CircularProgress />
              </div>
            ) : (
              <Button type="submit" variant="contained">
                Send email
              </Button>
            )}
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default ForgotPassword;
