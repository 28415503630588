import { useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import { ErrorMessage, useFormikContext } from 'formik';

import { FormField } from 'src/shared/models';
import TextError from '../TextError/TextError';

import defaultPicture from './images/default.png';
import './PicturePicker.scss';

type PicturePickerProps = {
  title?: string;
  name: string;
  onUpload?: (url: string | ArrayBuffer | null) => void;
  onDelete?: () => void;
  onUploadFile?: (url: File) => void;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  size?: number;
};

const PicturePicker = ({
  title,
  name,
  onUpload,
  onDelete,
  onUploadFile,
  className,
  disabled = false,
  required,
  size = 0,
}: PicturePickerProps) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const { setFieldValue, values, setErrors, setFieldTouched } =
    useFormikContext<FormField>();

  const onTrigger = () => {
    fileInput.current?.click();
  };

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((e.target.files && e.target.files[0].size <= size) || size === 0) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (fileReader.readyState === 2) {
          setFieldValue(name, fileReader.result);
          onUpload?.(fileReader.result);
        }
      };
      if (e.target.files) {
        fileReader.readAsDataURL(e.target.files[0]);
        onUploadFile?.(e.target.files[0]);
      }
    } else {
      const fileSizeMB = size / 1024 ** 2;
      setErrors({ [name]: `File size exceeds maximum limit, ${fileSizeMB}MB` });
      setFieldTouched(name, true, false);
    }
  };

  const deleteImage = () => {
    onDelete?.();
    setFieldValue(name, null);
  };

  useEffect(() => {
    const image = document.getElementById('profile-picture');
    if (image) {
      image.onerror = () => {
        image.setAttribute('src', defaultPicture);
      };
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnError = (e: any) => {
    e.target.src = defaultPicture;
  };

  return (
    <div className={className ?? ''}>
      {title ? (
        <h2 className="picture-picker__header">
          {title}
          {required ? <span className="input__label_required"> *</span> : null}
        </h2>
      ) : null}
      <div className="picture-picker__container">
        <img
          src={values[name] ?? './images/default.png'}
          onError={handleOnError}
          alt="Profile picture"
          className="picture-picker__img"
          id="profile-picture"
        />
        {!disabled && (
          <>
            <Button className="settings__form-button" onClick={onTrigger}>
              Upload
              <input
                ref={fileInput}
                name={name}
                accept="image/*"
                type="file"
                id="picture-picker"
                hidden
                onChange={uploadFile}
              />
            </Button>
            {values[name] ? (
              <Button
                className="settings__form-button_error"
                onClick={deleteImage}
              >
                Delete
              </Button>
            ) : null}
          </>
        )}
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default PicturePicker;
