import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';

import { ErrorResponse } from 'src/shared/models';
import Modal from 'src/shared/components/Modal/Modal';
import Input from 'src/shared/components/Input/Input';
import Table from 'src/shared/components/Table/Table';

import { State, store } from 'src/features/store/store';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CAMPAIGNS } from 'src/features/campaigns/slices/campaignsSlice';
import {
  useGetCampaignUsersQuery,
  useSendCampaignNotificationMutation,
} from 'src/features/campaigns/api/campaignsApi';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import { MODALS } from '../../models';

import './CampaignPreview.scss';

const SIZE = 10;

const CampaignPreview = () => {
  const campaignDetails = useSelector(
    (s: State) => s[CAMPAIGNS].campaignDetails
  );
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: SIZE,
  });
  const {
    data: users,
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
  } = useGetCampaignUsersQuery(
    {
      campaignUid: campaignDetails.uid ?? '',
      page: pagination.pageIndex,
      size: pagination.pageSize,
    },
    {
      skip: !campaignDetails.uid,
    }
  );
  const [sendCampaignNotification, sendNotificationResult] =
    useSendCampaignNotificationMutation();

  useEffect(() => {
    if (sendNotificationResult.isSuccess) {
      store.dispatch(changeModal(MODALS.CAMPAIGN_SUCCESS));
    }
    if (sendNotificationResult.isError) {
      toast.error(
        `Error: ${
          (sendNotificationResult.error as ErrorResponse).data.message
        }`,
        {
          containerId: CONTAINER_ID_ACTION,
        }
      );
    }
  }, [
    sendNotificationResult.error,
    sendNotificationResult.isError,
    sendNotificationResult.isSuccess,
  ]);

  const onSubmit = async () => {
    await sendCampaignNotification(campaignDetails.uid);
  };

  const columns: MRT_ColumnDef[] = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
    ],
    []
  );

  const campaignForm = (
    <Formik
      initialValues={campaignDetails}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form className="create-campaign">
        <Input
          name="title"
          label="Campaign title"
          placeholder="Write your title"
          disabled
        />
        <Input
          name="message"
          label="Campaign message"
          placeholder="Write your message"
          disabled
        />
        <Table
          columns={columns}
          data={users?.data ?? []}
          maxHeight="300px"
          manualPagination
          onPaginationChange={setPagination}
          rowCount={users?.count ?? 0}
          state={{
            pagination,
            isLoading: isUsersLoading,
            showProgressBars: isUsersFetching,
          }}
        />
      </Form>
    </Formik>
  );

  return (
    <Modal
      open={true}
      title="Campaign Preview"
      onBackClick={() => {
        store.dispatch(changeModal(null));
      }}
      onConfirmClick={onSubmit}
      backButtonText="Back to Edit"
      confirmButtonText="Send Campaign"
      content={campaignForm}
      loading={sendNotificationResult.isLoading}
      className={'campaign-preview-modal'}
    />
  );
};

export default CampaignPreview;
