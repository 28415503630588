import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';

import { PASSWORD_REGEX } from 'src/shared/utils';
import Input from 'src/shared/components/Input/Input';

import { paths } from 'src/app/routes';
import { useResetPasswordConfirmMutation } from 'src/features/auth/api/authApi';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';

type ResetPasswordValues = {
  password: string;
  passwordConfirm: string;
};

const initialResetPasswordValues = {
  password: '',
  passwordConfirm: '',
};

const validationSchema = Yup.object({
  password: Yup.string()
    .matches(PASSWORD_REGEX, 'Must contain 8 characters and one digit')
    .required('This field is required'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('This field is required'),
});

const ResetPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useSearchParams();
  const [successPage, setSuccessPage] = useState<boolean>();
  const [resetPassword, resetPasswordResult] =
    useResetPasswordConfirmMutation();
  const onSubmit = (value: ResetPasswordValues) =>
    resetPassword({
      email: (searchParams[0].get('email') as string).replace(' ', '+') ?? '',
      verificationCode: (searchParams[0].get('key') as string) ?? '',
      newPassword: value.password,
    });

  useEffect(() => {
    if (resetPasswordResult.isError) {
      toast.error('Resetting password error', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
    if (resetPasswordResult.isSuccess) {
      setSuccessPage(true);
    }
  }, [resetPasswordResult]);

  useEffect(() => {
    if (location.pathname === paths.resetPasswordConfirm) {
      navigate({
        pathname: paths.resetPassword,
        search: decodeURIComponent(searchParams[0].toString()),
      });
    }
  });

  return successPage ? (
    <>
      <h1 className="auth-header">Password changed!</h1>
      <h2 className="auth-subheader">
        The password has been successfully changed.
      </h2>
      <Button variant="contained" component={Link} to={paths.signIn}>
        Sign in to N-CAP
      </Button>
    </>
  ) : (
    <>
      <h1 className="auth-header">Reset password</h1>
      <h2 className="auth-subheader">
        Enter the new password. Password must be at least 8 characters long and
        contain one letter and one number.
      </h2>
      <Formik
        initialValues={initialResetPasswordValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form className="forgot-password">
          <Input name="password" label="New password" type="password" />
          <Input
            name="passwordConfirm"
            label="Confirm new password"
            type="password"
          />

          {resetPasswordResult.isLoading ? (
            <div className="reset-password__spinner-container">
              <CircularProgress />
            </div>
          ) : (
            <Button type="submit" variant="contained">
              Reset password
            </Button>
          )}
        </Form>
      </Formik>
    </>
  );
};

export default ResetPassword;
