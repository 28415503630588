import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { paths } from 'src/app/routes';

import { useLazyGetMyProfileQuery } from './auth/api/authApi';
import { useLazyUnsubscribeFromNotificationsQuery } from './notifications/api/notificationsApi';
import { CONTAINER_ID_ACTION } from './notifications/components/NotificationContainer/NotificationContainer';

export const useCheckError = (isError: boolean, message: string) => {
  useEffect(() => {
    if (isError) {
      toast.error(message, { containerId: CONTAINER_ID_ACTION });
    }
  }, [isError, message]);
};

export const useCheckAuthorization = () => {
  const navigate = useNavigate();
  const [getProfile] = useLazyGetMyProfileQuery();
  const [unsubscribe] = useLazyUnsubscribeFromNotificationsQuery();

  const cleanAndRedirect = useCallback(() => {
    localStorage.removeItem('ncap-token');
    navigate(paths.signIn);
    window.location.reload();
  }, [navigate]);

  useEffect(() => {
    getProfile()
      .unwrap()
      .catch(async () => {
        const firebaseToken = localStorage.getItem('firebase-device-token');
        if (firebaseToken) {
          Promise.all([
            unsubscribe({ deviceToken: firebaseToken, topic: 'pya' }),
            unsubscribe({ deviceToken: firebaseToken, topic: 'guest' }),
          ])
            .then(() => cleanAndRedirect())
            .catch((error) => console.log(error));
        } else {
          cleanAndRedirect();
        }
      });
  }, [cleanAndRedirect, getProfile, navigate, unsubscribe]);
};
