import { ResponsiblePerson } from 'src/features/responsiblePersons/models';

export enum TestimonialRequestStatus {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  SENT_RP = 'SENT_RP',
  SENT_PYA = 'SENT_PYA',
  VALIDATED_PYA = 'VALIDATED_PYA',
  REVISION_RP = 'REVISION_RP',
  REJECTED = 'REJECTED',
}

export enum TestimonialType {
  DECK = 'Deck',
  ENGINEERING = 'Engineering',
  DUAL = 'Dual',
  INTERIOR = 'Interior',
}

export enum DeckCapacity {
  MASTER = 'Master',
  CHIEF_MATE = 'Chief Mate',
  OOW = 'OOW',
  DECKHAND = 'Deckhand',
}

export enum EngineeringCapacity {
  CHIEF_ENGINEER = 'Chief Engineer',
  ENGINEER_WATCHKEEPER = 'Engineer Watchkeeper',
  SECOND_ENGINEER = 'Second Engineer',
}

export enum DualCapacity {
  MASTER = 'Master',
  CHIEF_MATE = 'Chief Mate',
  OOW = 'OOW',
  DECKHAND = 'Deckhand',
  CHIEF_ENGINEER = 'Chief Engineer',
  ENGINEER_WATCHKEEPER = 'Engineer Watchkeeper',
  SECOND_ENGINEER = 'Second Engineer',
}

export enum InteriorCapacity {
  CHEF = 'Chef',
  COOK = 'Cook',
  STEWARD = 'Steward/ess',
  CHIEF_STEWARD = 'Chief Steward/ess',
  DECKHAND = 'Deckhand',
}

export enum VesselType {
  MOTOR_YACHT = 'Motor Yacht',
  SAILING_YACHT = 'Sailing Yacht',
}

export const AREAS_OPTIONS = [
  {
    label: 'Eastern Mediterranean',
    value: 'Eastern Mediterranean',
  },
  {
    label: 'Western Mediterranean',
    value: 'Western Mediterranean',
  },
  {
    label: 'Caribbean',
    value: 'Caribbean',
  },
  {
    label: 'USA East Coast',
    value: 'USA East Coast',
  },
  {
    label: 'USA West Coast',
    value: 'USA West Coast',
  },
  {
    label: 'Persian Gulf / Middle East',
    value: 'Persian Gulf / Middle East',
  },
  {
    label: 'Atlantic Ocean',
    value: 'Atlantic Ocean',
  },
  {
    label: 'Pacific Ocean',
    value: 'Pacific Ocean',
  },
  {
    label: 'Indian Ocean',
    value: 'Indian Ocean',
  },
  {
    label: 'Southern Ocean',
    value: 'Southern Ocean',
  },
  {
    label: 'Arctic Ocean',
    value: 'Arctic Ocean',
  },
  {
    label: 'North Sea',
    value: 'North Sea',
  },
  {
    label: 'Asia',
    value: 'Asia',
  },
  {
    label: 'Australasia',
    value: 'Australasia',
  },
  {
    label: 'Gulf of Alaska',
    value: 'Gulf of Alaska',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export interface TestimonialRequest {
  uid: string;
  date: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  responsiblePerson: string;
  responsiblePersonVerified: boolean;
  paymentStatus: string;
  certificateId: string;
}

interface Seafarer {
  firstName: string;
  middleName: string;
  lastName: string;
  email?: string;
  dateOfBirth: string;
  capacity?: string;
  capacityOther?: string;
  phoneNumber?: string;
  pyaMembershipNo: string;
  cocNo?: string;
  cocAuthority?: string;
  position?: string;
  orgAndAddress?: string;
}

interface Vessel {
  vesselType?: string;
  vesselTypeOther?: string;
  name: string;
  flag: string;
  officialNumber?: string;
  imoNumber: string;
  length: number;
  grossTonnage: number;
  mainEngineType: string;
  mainEnginePower: number;
}

export interface LeaveDates {
  from: string;
  to: string;
}

export interface Testimonial {
  uid: string;
  testimonialType?: string;
  status: string;
  createdOn?: string;
  certificateId: string;
  dateFrom?: string;
  dateTo?: string;
  actualDays: number;
  nauticalMiles?: number;
  daysGuests?: number;
  daysStandBy: number;
  daysShipyard: number;
  daysWatchKeeping: number;
  daysSeagoingWatchKeeping?: number;
  daysAdditionalWatchKeeping?: number;
  daysLeave: number;
  totalDays: number;
  leaveDates: LeaveDates[];
  rotationProgram: string;
  areasCruised?: string[];
  additionalInformation?: string;
  seafarer: Seafarer;
  vessel: Vessel;
  responsiblePerson: ResponsiblePerson;
  rejectionReason?: string;
}

export interface GetTestimonialRequestsResponse {
  data: TestimonialRequest[];
}

export interface GetTestimonialByIdResponse {
  data: Testimonial;
}

export interface UpdateTestimonialById {
  uid: string;
  testimonialType?: string;
  status?: string;
  createdOn?: string;
  dateFrom?: string;
  dateTo?: string;
  actualDays?: number;
  nauticalMiles?: number;
  daysGuests?: number;
  daysStandBy?: number;
  daysShipyard?: number;
  daysWatchKeeping?: number;
  daysSeagoingWatchKeeping?: number;
  daysAdditionalWatchKeeping?: number;
  daysLeave?: number;
  totalDays?: number;
  leaveDates?: LeaveDates[];
  rotationProgram?: string;
  areasCruised?: string[];
  additionalInformation?: string;
  seafarer?: Seafarer;
  vessel?: Vessel;
  responsiblePerson?: ResponsiblePerson;
  rejectionReason?: string;
}

export interface ChangeRequestStatus {
  uid: string;
  status: TestimonialRequestStatus;
}
