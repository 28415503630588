import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Template } from '../models';

export const TEMPLATES = 'TEMPLATES';

interface TemplatesState {
  template: Template | null;
  renderParamsNames: string[];
}

const initialState: TemplatesState = {
  template: {
    uid: '',
    name: '',
    description: '',
    image: '',
    renderParams: {},
    designTemplateUid: '',
  },
  renderParamsNames: [],
};

const templatesSlice = createSlice({
  name: TEMPLATES,
  initialState,
  reducers: {
    setTemplate: (
      state: TemplatesState,
      action: PayloadAction<Template | null>
    ) => {
      state.template = action.payload;
    },
    setRenderParamsNames: (
      state: TemplatesState,
      action: PayloadAction<string[]>
    ) => {
      state.renderParamsNames = action.payload;
    },
  },
});

export const { setTemplate, setRenderParamsNames } = templatesSlice.actions;

export default templatesSlice.reducer;
