import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { addAuthorizationHeaderWithBearer } from 'src/shared/api/utils';

import {
  CreateCredentialTemplateRequest,
  CreateTemplateForCompanyRequest,
  CreateTemplateForUserRequest,
  GetTemplatesResponse,
  UpdateTemplateRequest,
} from '../models';
import { DefaultResponse } from 'src/shared/models';

export const TEMPLATES_API = 'TEMPLATES_API';

const templatesApi = createApi({
  reducerPath: TEMPLATES_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_TEMPLATES_URL,
      prepareHeaders: (headers) => addAuthorizationHeaderWithBearer(headers),
    }),
    { maxRetries: 0 }
  ),
  tagTypes: ['TEMPLATES'],
  endpoints: (builder) => ({
    getTemplates: builder.query<GetTemplatesResponse, void>({
      query: () => ({
        method: 'GET',
        url: 'credential/credential-templates',
      }),
      providesTags: ['TEMPLATES'],
    }),
    createTemplate: builder.mutation<
      DefaultResponse,
      CreateCredentialTemplateRequest
    >({
      query: (request) => ({
        method: 'POST',
        url: 'credential/credential-templates',
        body: request,
      }),
      invalidatesTags: ['TEMPLATES'],
    }),
    updateTemplate: builder.mutation<void, UpdateTemplateRequest>({
      query: (request) => ({
        method: 'PUT',
        url: 'credential/credential-templates',
        body: request,
      }),
      invalidatesTags: ['TEMPLATES'],
    }),
    getTemplatesByCompany: builder.query<GetTemplatesResponse, string>({
      query: (companyUid: string) => ({
        method: 'GET',
        url: `credential/credential-templates/companies/${companyUid}`,
      }),
      providesTags: ['TEMPLATES'],
    }),
    createTemplateForCompany: builder.mutation<
      DefaultResponse,
      CreateTemplateForCompanyRequest
    >({
      query: (request) => ({
        method: 'POST',
        url: 'credential/credential-templates/companies',
        body: request,
      }),
      invalidatesTags: ['TEMPLATES'],
    }),
    createTemplateForUser: builder.mutation<
      DefaultResponse,
      CreateTemplateForUserRequest
    >({
      query: (request) => {
        return {
          method: 'POST',
          url: 'user-profile/enterprise-user/template',
          body: request,
        };
      },
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useLazyGetTemplatesQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useLazyGetTemplatesByCompanyQuery,
  useCreateTemplateForCompanyMutation,
  useCreateTemplateForUserMutation,
} = templatesApi;

export const templatesMiddleware = templatesApi.middleware;

export default templatesApi.reducer;
